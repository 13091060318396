import React, { useEffect, useState } from 'react';
import PageTitle from '../../../components/PageTitle';
import FolderItem from '../../../components/FolderItem';
import { Case } from "../../../helpers/interfaces";
import { useNavigate } from "react-router-dom";
import CaseApi from "../../../api/case-api";
import { Button, Card, Form, Modal, Spinner } from "react-bootstrap";


const caseApi = new CaseApi();
const Cases = (props: any) => {
    let navigate = useNavigate();
    const [cases, setCases] = useState<Case[]>([]);
    const [isLoading, setIsLoading] = useState(false)
    const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
    const [showAddCaseModal, setShowAddCaseModal] = useState<boolean>(false);

    // Fetch list of cases
    useEffect(() => {
        async function fetchCases() {
            setIsLoading(true);
            try {
                const resp = await caseApi.listCases({
                    offset: 0,
                    limit: 100
                })
                setCases(resp.cases);
            } catch (e) {
                console.log("Error loading cases", e)
            } finally {
                setIsLoading(false)
            }
        }

        fetchCases();
    }, [])

    const onCaseClicked = (_case: Case) => {
        navigate(`/cases/${_case.id}/locations`, { state: _case})
    }

    const toggleAddCaseModal = () => {
        setShowAddCaseModal(!showAddCaseModal);
    }

    const toggleSettingsModal = () => {
        setShowSettingsModal(!showSettingsModal);
    }

    const saveCaseSettings = () => {
        toggleSettingsModal();
    }

    function addCase() {

    }

    return (
        <React.Fragment>
            <PageTitle
                breadCrumbItems={[
                    {label: "Cases", path: "/cases"},
                    {label: "View Cases", active: true}
                ]}
                title="View Cases"
                actionBtnText="Add Case"
            />
            <Card>
                <Card.Body>
                    {isLoading &&
                        <div className="d-flex justify-content-center">
                            <Spinner className="text-primary m-2"/>
                        </div>
                    }
                    <div className="folder-list-container">
                        {cases.length === 0 && <span className="text-muted">No cases found</span>}
                        {!isLoading && cases.map((Case, idx) => {
                            return (
                                <React.Fragment key={Case.id}>
                                    <FolderItem
                                        onClick={() => onCaseClicked(Case)}
                                        title={Case.name}></FolderItem>
                                </React.Fragment>
                            )
                        })}
                    </div>

                    <Modal
                        show={showAddCaseModal}
                        onHide={toggleAddCaseModal}
                        dialogClassName="modal-dialog-centered">
                        <Modal.Header
                            onHide={toggleAddCaseModal} closeButton>
                            <h4 className="modal-title">Add Case
                            </h4>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text"></Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control as="textarea"></Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Assign Camera</Form.Label>
                                    <Form.Select>
                                        <option>--</option>
                                        <option>Cam 1</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Assign User</Form.Label>
                                    <Form.Select>
                                        <option>--</option>
                                        <option>John Doe</option>
                                    </Form.Select>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="primary"
                                onClick={addCase}>
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showSettingsModal}
                           onHide={toggleSettingsModal}
                           dialogClassName="modal-dialog-centered">
                        <Modal.Header onHide={toggleSettingsModal} closeButton>
                            <h4 className="modal-title">Manage Case: <span
                                className="text-primary">{"Case Name"}</span>
                            </h4>
                        </Modal.Header>
                        <Modal.Body>
                            <form className="ps-3 pe-3" action="#">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label htmlFor="field-3" className="fw-bold form-label">
                                                Assign Camera
                                            </label>
                                            <input
                                                type="select"
                                                className="form-control"
                                                id="field-3"
                                                placeholder="Cam1"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label htmlFor="field-3" className="fw-bold form-label">
                                                Assign User
                                            </label>
                                            <input
                                                type="select"
                                                className="form-control"
                                                id="field-3"
                                                placeholder="John Doe"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="primary"
                                onClick={saveCaseSettings}>
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}

export default Cases
