import React from "react";
import { Row, Col, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";


/**
 * PageTitle
 */
const PageTitle = (props: any) => {
    return (
        <Row>
            <Col>
                <div className="page-title-box">
                    <div className="page-title-right">
                        <Breadcrumb className="m-0">
                            {(props.breadCrumbItems).map((item: any, index: number) => {
                                return item.active ? (
                                    <li key={index} className="breadcrumb-item active">
                                        {item.label}
                                    </li>
                                ) : (
                                    <li key={index} className="breadcrumb-item">
                                        <Link to={item.path}>{item.label}</Link>
                                    </li>
                                );
                            })}
                        </Breadcrumb>
                    </div>

                    <h4 className="page-title">{props["title"]}</h4>
                </div>
            </Col>
        </Row>
    );
};

PageTitle.defaultProps = {
    breadCrumbItems: []
}

export default PageTitle;
