import React from "react";
import { Row, Col } from "react-bootstrap";
import packageJson from "../../../package.json"

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const appVersion = packageJson.version

  return (
    <React.Fragment>
      <footer className="footer">
        <div className="container-fluid">
          <Row>
            <Col md={6}>
              <div className="d-none text-white d-md-flex gap-4 align-item-center justify-content-md-start footer-links">
                <span>Copyright &copy; Covert Captures {currentYear}</span>
                <span>Version {appVersion}</span>
              </div>
            </Col>

            <Col md={6}>
              <div className="d-none text-white d-md-flex gap-4 align-item-center justify-content-md-end footer-links">
                <a href="mailto:info@CovertCaptures.com" target="_blank" rel="noopener noreferrer">Contact Us</a>
              </div>
            </Col>
          </Row>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
