import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import LogoDark from "../../assets/images/logo-dark.jpg";
import HeroVideo from "../../assets/images/hero-video-bg.mp4";


const AuthLayout = ({bottomLinks, children}: any) => {

  return (
    <div className="auth-fluid">
      <div className="auth-fluid-form-box">
        <div className="align-items-center d-flex h-100">
          <Card.Body>
            {/* logo */}
            <div className="auth-brand text-center text-lg-start">
              <div className="auth-logo">
                <Link
                  to="/"
                  className="logo logo-dark text-center outline-none"
                >
                    <span className="logo-lg">
                      <img src={LogoDark} alt="" height="56"/>
                    </span>
                </Link>
              </div>
            </div>

            {children}

            {/* footer links */}
            {bottomLinks}
          </Card.Body>
        </div>
      </div>

      {/* Auth fluid right content */}
      <div className="auth-fluid-right text-center">
        <div className="auth-video-container">
          <video className="auth-video" playsInline={true} autoPlay muted loop>
            <source src={HeroVideo} type="video/mp4"/>
          </video>
        </div>
        <div className="auth-video-overlay"></div>
        <div className="auth-promotional-text">
          <h2 className="mb-3 auth-title">Covert Captures</h2>
            <p className="lead">
              <i className="mdi mdi-format-quote-open"></i>{" "}
              This website and its content are restricted to Covert Captures personnel and their clients.
              Any illegal or improper access will result in prosecution.
              <i className="mdi mdi-format-quote-close"></i>
            </p>
            <h5 className="auth-phone">
              Info@CovertCaptures.com
            </h5>
        </div>
      </div>


    </div>
  );

}

export default AuthLayout;