import React, { ComponentType, useEffect, useState } from "react";
import { useReactTable, getCoreRowModel, flexRender, FilterFn } from "@tanstack/react-table";
import { Col, Row } from "react-bootstrap";
import classNames from "classnames";

type TableProps = {
    data: any[];
    columns: any[];
    isSearchable?: boolean;
    onRowClicked: any;
    actionButton?: ComponentType,
    classNames?: string;
}

const Table = (props: TableProps) => {
    const [globalFilter, setGlobalFilter] = React.useState('')

    const filterFn: FilterFn<any> = () => {
        // TODO
        return true;
    }

    const dataTable = useReactTable({
        data: props["data"],
        columns: props["columns"],
        state: {
            globalFilter
        },
        getCoreRowModel: getCoreRowModel(),
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: filterFn
    })

    const SearchBox = (props: any) => {
        const count = props.preFilteredRows?.length;
        const [value, setValue] = useState<any>();

        const onChange = (value: any) => {
            setGlobalFilter(value);
        }

        // Debounce
        useEffect(() => {
            const timeout = setTimeout(() => {
                onChange(value);
            }, props.debounce)
            return () => clearTimeout(timeout);
        }, [value]);

        return (
            <React.Fragment>
                <div>
                    <span className="d-flex align-items-center">
                        Search :{" "}
                        <input
                            type="text"
                            value={value || ""}
                            onChange={(e: any) => {
                                setValue(e.target.value);
                                onChange(e.target.value);
                            }}
                            placeholder={`${count} records...`}
                            className="form-control w-auto ms-1"
                        />
                    </span>
                </div>

            </React.Fragment>
        );
    }


    return (
        <React.Fragment>
            <Row className="mb-3">
                <Col sm={8} className="d-flex align-items-center">
                    {props.isSearchable && <SearchBox
                        debounce={500}
                        preFilteredRows={dataTable.getPreFilteredRowModel().rows}
                    />}
                </Col>
                <Col sm={4} className="d-flex justify-content-end">
                    {props.actionButton &&
                        <props.actionButton/>
                    }
                </Col>
            </Row>
            <div className="table-responsive">
                <table className={classNames("table table-centered react-table", props.classNames)}>
                    <thead className="table-light">
                    {dataTable.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th key={header.id}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                    {dataTable.getRowModel().rows.map(row => (
                        <tr key={row.id} onClick={() => props.onRowClicked(row.original)}>
                            {row.getVisibleCells().map(cell => (
                                <td key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
}

Table.defaultProps = {
    onRowClicked: () => {
    }
}

export default Table