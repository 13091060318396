import { BackendApi } from "./backend-api";


export default class FileApi {
    private backendApi: BackendApi;

    constructor() {
        this.backendApi = BackendApi.Instance;
    }

    async downloadFile(filename: string) {
        return this.backendApi.getApiResponse({
            endPoint: `/file/${filename}`,
            method: `GET`
        })
    }


}