export const useDebounce = () => {
    return (func: any, delay = 500) => {
        let timerId: any;
        return (...args: any) => {
            clearTimeout(timerId);
            timerId = setTimeout(() => {
                func.apply(null, args);
            }, delay);
        }
    };
}