import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { Camera, Case, CaseLocation } from "../../helpers/interfaces";
import CaseApi from "../../api/case-api";
import CameraApi from "../../api/camera-api";

const caseApi = new CaseApi();
const cameraApi = new CameraApi();

const CameraAssignModal = (props: any) => {
    const [cameraInfo, setCameraInfo] = useState<Camera>(props.camera)
    const [isLoadingCases, setIsLoadingCases] = useState(false);
    const [cases, setCases] = useState<Case[]>([]);
    const [isLoadingLocations, setIsLoadingLocations] = useState(false);
    const [locations, setLocations] = useState<CaseLocation[]>([]);
    const [isSaving, setIsSaving] = useState(false)
    const [serverError, setServerError] = useState<any>({});
    const [showUnAssignWarning, setShowUnAssignWarning] = useState(false);

    useEffect(() => {
        async function fetchCases() {
            try {
                setIsLoadingCases(true);
                const resp = await caseApi.listCases({
                    offset: 0,
                    limit: 1000,
                });
                setCases([...resp.cases]);
            } catch (e) {
                console.error('Error loading users');
            } finally {
                setIsLoadingCases(false);
            }
        }

        fetchCases();
    }, []);

    useEffect(() => {
        async function fetchLocations() {
            if (!cameraInfo?.case_id) {
                return;
            }
            try {
                setIsLoadingLocations(true);
                const resp = await caseApi.listLocations({
                    offset: 0,
                    limit: 1000,
                    caseId: cameraInfo.case_id
                });
                setLocations([...resp.locations]);
                // Set selected location to the first item in the list
                updateSelectedLocation(resp.locations[0]?.id);
            } catch (e) {
                console.error('Error loading users');
            } finally {
                setIsLoadingLocations(false);
            }
        }

        fetchLocations();
    }, [cameraInfo.case_id]);

    const updateSelectedLocation = (locationId: number | null) => {
        setCameraInfo((prevState: Camera) => {
            return {
                ...prevState,
                "location_id": locationId
            }
        });
    }

    const updateSelectedCase = (caseId: number | null) => {
        setCameraInfo((prevState: Camera) => {
            return {
                ...prevState,
                "case_id": caseId
            }
        });
    }

    const onLocationSelected = (event: any) => {
        const locationId = event.target.value;
        updateSelectedLocation(locationId);
    }

    const onCaseSelected = (event: any) => {
        const caseId: any = event.target.value;
        updateSelectedCase(caseId);
    }

    const toggleUnAssignWarning = () => {
        setServerError(null)
        setShowUnAssignWarning(!showUnAssignWarning);
    }

    const onSave = async (UnAssign = false) => {
        try {
            setIsSaving(true);

            if (UnAssign) {
                await cameraApi.unassignCamera(cameraInfo.id);
            } else {
                await cameraApi.assignCamera(
                    cameraInfo.id,
                    cameraInfo.location_id,
                    cameraInfo.case_id);
            }
            props.onSuccess("Camera Updated successfully");
            props.onHide();
        } catch (e: any) {
            console.log(e)
            setServerError(e)
        } finally {
            setIsSaving(false);
        }
    }

    return (
        <React.Fragment>
            <Modal
                show={true}
                onHide={props.onHide}
                dialogClassName="modal-dialog-centered">
                <Modal.Header
                    onHide={props.onHide} closeButton>
                    <h4 className="modal-title"> {showUnAssignWarning ? "Unassign" : "Assign"}
                        <span className="fw-bold text-info"> {cameraInfo.name}</span> ?
                    </h4>
                </Modal.Header>
                <Modal.Body>
                    {
                        showUnAssignWarning ? (
                                <p>Are you sure you want to unassign this camera from the case
                                    <span className="fw-bold"> {cameraInfo.case_name}</span> at location
                                    <span className="fw-bold"> {cameraInfo.location_name}</span>?
                                </p>
                            ) :
                            <React.Fragment>
                                <Form.Group className="mb-3" controlId="case">
                                    <Form.Label className="text-bold">Select Case</Form.Label>
                                    <Form.Select
                                        required
                                        disabled={isLoadingCases}
                                        onChange={onCaseSelected}
                                        value={cameraInfo?.case_id || ""}
                                        name="case_id"
                                        aria-label="Case">
                                        <option disabled value="">Select option</option>
                                        {cases.map((val, idx) => (
                                            <option
                                                key={idx}
                                                value={val.id}>{val.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select an option
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="location">
                                    <Form.Label className="text-bold">Select location</Form.Label>
                                    <Form.Select
                                        required
                                        disabled={isLoadingLocations || !cameraInfo.case_id}
                                        onChange={onLocationSelected}
                                        value={cameraInfo?.location_id || ""}
                                        name="location_id"
                                        aria-label="Choose Location">
                                        <option disabled
                                                value="">{!cameraInfo.case_id ? "Select a Case first" : "Select option"}</option>
                                        {locations.map((val, idx) => (
                                            <option
                                                key={idx}
                                                value={val.id}>{val.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select an option
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </React.Fragment>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {serverError && <p className="text-danger">{serverError?.data?.error}</p>}
                    {props.isSaving && <Spinner className="m-2"/>}
                    {isSaving &&
                        <Spinner className="me-3" variant="primary"></Spinner>
                    }
                    {props.camera.case_id !== undefined && !showUnAssignWarning && <Button
                        disabled={props.isSaving}
                        onClick={toggleUnAssignWarning}
                        variant="danger">
                        Unassign
                    </Button>
                    }
                    {showUnAssignWarning && <Button
                        disabled={props.isSaving}
                        onClick={() => onSave(true)}
                        variant="danger">
                        Yes
                    </Button>}

                    {showUnAssignWarning && <Button
                        disabled={props.isSaving}
                        onClick={toggleUnAssignWarning}
                        variant="secondary">
                        Cancel
                    </Button>}

                    {!showUnAssignWarning && <Button
                        disabled={props.isSaving || cameraInfo.case_id === undefined}
                        onClick={() => onSave()}
                        variant="primary">
                        Assign
                    </Button>}
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}

export default CameraAssignModal;