import React, { useEffect, useMemo, useState } from 'react';
import PageTitle from '../../../components/PageTitle';
import { Button, Card, Spinner } from "react-bootstrap";
import Table from '../../../components/Table'
import { Link, useNavigate } from "react-router-dom";
import CaseApi from "../../../api/case-api";
import { Case } from "../../../helpers/interfaces";
import CaseFormModal from "../CaseFormModal";
import DeleteCaseModal from "./DeleteCaseModal";

const caseApi = new CaseApi();

const ManageCases = () => {
    const navigate = useNavigate();
    const [selectedCase, setSelectedCase] = useState<Case>();
    const [showCaseFormModal, setShowCaseFormModal] = useState(false);
    const [modalAction, setModalAction] = useState<string>();
    const [formError, setFormError] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [cases, setCases] = useState<Case[]>([]);
    const [isLoading, setIsLoading] = useState(false)
    const [showDeleteCaseModal, setShowDeleteCaseModal] = useState(false);

    // Fetch list of cases
    useEffect(() => {
        async function fetchCases() {
            setIsLoading(true);
            setCases([]);
            try {
                const resp = await caseApi.listCases({
                    offset: 0,
                    limit: 1000
                })
                setCases([...resp.cases]);
            } catch (e) {
                console.log("Error loading cases", e)
            } finally {
                setIsLoading(false)
            }
        }

        fetchCases();
    }, [])

    const columns = useMemo(() => [
        {
            header: "Name",
            accessorKey: "name",
        },
        {
            header: "Description",
            accessorKey: "description",
        },
        {
            header: "Assigned Camera",
            accessorKey: "camera",
            cell: (prop: any) => CameraColumn(prop),
        },
        {
            header: "Date Added",
            accessorKey: "created_at",
            sort: true,
            cell: (prop: any) => prop.getValue().split('T')[0],
        },
        {
            header: "Actions",
            accessorKey: "actions",
            cell: (prop: any) => ActionColumn(prop)
        }
    ], []);

    const CameraColumn = (prop: any) => {
        return (
            <Link to={`/cameras/${prop.row.original.camera_id}`}>{prop.getValue()}</Link>
        );
    }

    const ActionColumn = (prop: any) => {
        const _case = prop.row.original;
        return (
            <>
                <Link to="#" onClick={(event) => {
                    event.stopPropagation();
                    return toggleCaseModal("edit", _case)
                }}>
                    {" "}
                    <span className="material-symbols-outlined action-icon">edit</span>
                </Link>
                <Link to="#" onClick={(event) => {
                    event.stopPropagation();
                    return toggleDeleteCaseModal(_case)
                }}>
                    {" "}
                    <span className="material-symbols-outlined action-icon action-delete">delete</span>
                </Link>
                <Link to="#" onClick={() => navigate(`${_case.id}`)}>
                    {" "}
                    <span className="material-symbols-outlined action-icon">visibility</span>
                </Link>
            </>
        );
    };


    const toggleCaseModal = (action?: string, caseToEdit?: Case) => {
        setSelectedCase(caseToEdit);
        setShowCaseFormModal(!showCaseFormModal);
        setModalAction(action);
        setFormError({});
    }

    const toggleDeleteCaseModal = (case_?: any) => {
        setShowDeleteCaseModal(!showDeleteCaseModal);
        setSelectedCase(case_);
    }

    const onUpdateCase = async (newData: any) => {
        try {
            setIsSaving(true);
            const updatedCase = await caseApi.updateCase(newData);
            // update table
            const idx = cases.findIndex(_case => _case.id === updatedCase.id);
            cases[idx] = updatedCase
            setCases([...cases])
        } catch (e: any) {
            setFormError(e)
        } finally {
            toggleCaseModal();
            setIsSaving(false);
        }
    }

    const onAddCase = async (_case: any) => {
        try {
            setIsSaving(true);
            const resp = await caseApi.addCase(_case);
            cases.push(resp);
            setCases([...cases]);
        } catch (e: any) {
            setFormError(e)
        } finally {
            toggleCaseModal();
            setIsSaving(false);
        }
    }

    const onDeleteCase = async (caseToDelete?: Case) => {
        if (!caseToDelete) {
            return;
        }
        try {
            setIsSaving(true);
            await caseApi.deleteCase(caseToDelete.id);
            // Update table
            const idx = cases.findIndex(cam => cam.id === caseToDelete.id);
            cases.splice(idx, 1);
            setCases([...cases]);
        } catch (e: any) {
            setFormError(e)
        } finally {
            toggleDeleteCaseModal()
            setIsSaving(false);
        }
    }

    const onSaveCase = (_case: any) => {
        if (modalAction === "edit") {
            return onUpdateCase(_case)
        }
        if (modalAction === "add") {
            return onAddCase(_case);
        }
    }

    const onRowClicked = (_case: Case) => {
        navigate(`${_case.id}`)
    }

    const AddButton = () => {
        return (
            <React.Fragment>
                <Button
                    variant="primary"
                    className="btn-add"
                    onClick={() => toggleCaseModal("add")}>
                    Add Case
                </Button>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <PageTitle
                breadCrumbItems={[
                    {label: "Cases", path: "/cases"},
                    {label: "Manage Cases", path: "/manage", active: true},
                ]}
                title="Manage Cases"
            />
            <Card>
                <Card.Body>
                    <Table
                        classNames="table-hover table-striped"
                        isSearchable={true}
                        actionButton={AddButton}
                        onRowClicked={onRowClicked}
                        columns={columns}
                        data={cases}
                    />
                    {!isLoading && (cases.length === 0 &&
                        <span>No cases found</span>
                    )}
                    {isLoading &&
                        <div className="d-flex justify-content-center">
                            <Spinner className="text-primary m-2"/>
                        </div>
                    }
                    <CaseFormModal
                        show={showCaseFormModal}
                        action={modalAction}
                        caseInfo={selectedCase}
                        isSaving={isSaving}
                        onHide={toggleCaseModal}
                        onSave={onSaveCase}
                        serverError={formError}
                    ></CaseFormModal>

                    <DeleteCaseModal
                        show={showDeleteCaseModal}
                        caseToDelete={selectedCase}
                        isSaving={isSaving}
                        onDelete={onDeleteCase}
                        onHide={toggleDeleteCaseModal}/>
                </Card.Body>
            </Card>

        </React.Fragment>
    )
}

export default ManageCases
