import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { Camera } from "../../helpers/interfaces";


const CameraFormModal = (props: any) => {
    const [cameraInfo, setCameraInfo] = useState<Camera>(props.cameraInfo)
    const [serverError, setServerError] = useState<any>({});
    const [nameInputTouched, setNameInputTouched] = useState(false)
    const [macInputTouched, setMacInputTouched] = useState(false)

    // Derived values
    const modalTitlePrefix = props.action.charAt(0).toUpperCase() + props.action.slice(1);
    const nameIsValid = Boolean(cameraInfo.name && cameraInfo.name.trim() !== '');

    const macAddressIsValid = ((): boolean => {
        if (!cameraInfo.mac_address || cameraInfo.mac_address.trim() === '') {
            return false;
        }
        const hexMatcher = /[0-9A-Fa-f]/g;
        const matchedArray = cameraInfo.mac_address.match(hexMatcher);
        matchedArray && matchedArray.join('');
        return !(!matchedArray || matchedArray.length !== 12);

    })();

    useEffect(() => {
        setServerError(props.serverError);
    }, [props.serverError]);

    const handleInputChange = (event: any) => {
        resetFormErrors();
        setCameraInfo((prevState: Camera) => {
            return {
                ...prevState,
                [event.target.name]: event.target.value
            }
        });
    }

    const resetFormErrors = () => {
        setNameInputTouched(false);
        setMacInputTouched(false)
        setServerError({});
    }

    const getFormattedMacAddress = (macAddress: string): string => {
        const hexMatcher = /[0-9A-Fa-f]/g;
        const matchedArray = macAddress.match(hexMatcher);
        matchedArray && matchedArray.join('');

        let formattedMacAddress = "";
        matchedArray?.forEach((value: any, idx: number) => {
            if (idx > 0 && idx % 2 === 0) {
                formattedMacAddress += ":"
            }
            formattedMacAddress += value;
        })
        return formattedMacAddress.toUpperCase();
    }

    const onInputTouched = (input: string) => {
        if (input === 'name') {
            setNameInputTouched(true)
        }
        if (input === 'mac_address') {
            setMacInputTouched(true)
        }
    }

    const hasServerError = () => {
        if (!serverError.data) {
            return false;
        }
        if (serverError.statusCode === 409) {
            return true;
        }
    }

    const onFormSubmit = (event: any) => {
        event.preventDefault();
        setNameInputTouched(true);
        setMacInputTouched(true);

        if (!nameIsValid) {
            return;
        }
        if (!macAddressIsValid) {
            return;
        }
        cameraInfo.mac_address = getFormattedMacAddress(cameraInfo.mac_address);
        props.onSave(cameraInfo)
    }

    return (
        <Modal
            show={true}
            onHide={props.onHide}
            dialogClassName="modal-dialog-centered">
            <Modal.Header
                onHide={props.onHide} closeButton>
                <h4 className="modal-title">{modalTitlePrefix} Camera
                </h4>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3" controlId="cameraName">
                    <Form.Label className="text-bold">Name *</Form.Label>
                    <Form.Control
                        type="text"
                        required
                        name="name"
                        isValid={nameInputTouched && nameIsValid}
                        isInvalid={nameInputTouched && !nameIsValid}
                        maxLength={150}
                        onBlur={() => onInputTouched('name')}
                        onChange={handleInputChange}
                        value={cameraInfo.name || ""}>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        Please enter valid name
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="cameraMacAddress">
                    <Form.Label className="text-bold">MAC Address *</Form.Label>
                    <Form.Control
                        type="text"
                        required
                        maxLength={17}
                        isValid={macInputTouched && macAddressIsValid}
                        isInvalid={(macInputTouched && !macAddressIsValid) || hasServerError()}
                        name="mac_address"
                        onBlur={() => onInputTouched('mac_address')}
                        onChange={handleInputChange}
                        value={cameraInfo.mac_address || ""}>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        {serverError.statusCode === 409 ? "MAC address already in use" :
                            "Please enter valid MAC address"
                        }
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="cameraDescription">
                    <Form.Label className="text-bold">Description</Form.Label>
                    <Form.Control
                        as="textarea"
                        name="description"
                        maxLength={300}
                        onChange={handleInputChange}
                        value={cameraInfo?.description || ""}></Form.Control>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                {serverError && <p className="text-danger">{serverError?.data?.error}</p>}
                {props.isSaving && <Spinner className="m-2"/>}
                <Button
                    disabled={props.isSaving}
                    onClick={onFormSubmit}
                    variant="primary"
                    type="submit">
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
export default CameraFormModal;