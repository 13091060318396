import React, { createContext, Suspense, useCallback, useState } from "react";
import { Outlet } from 'react-router-dom';
import LeftSidebar from './LeftSidebar';
import Footer from './Footer';
import { Container, Toast, ToastContainer } from 'react-bootstrap';
import Topbar from './Topbar';

const loading = () => <div className=""></div>;

type ToastNotification = {
    message: string;
    [key: string]: any
}

type IAppContext = {} & {
    showToast: (msg: string, type: string) => void,
    [key: string]: any
}

export const AppContext = createContext<IAppContext>({
    showToast(msg: string, type: string): void {
        throw new Error("Not Implemented")
    }
})

function RootLayout() {
    const [toastMessages, setToastMessages] = useState<ToastNotification[]>([])

    const showToastMessage = useCallback((message: string, type = "warning") => {
        setToastMessages(prevState => {
            const msg: ToastNotification = {
                message: message,
                type: type
            }
            prevState.push(msg);
            return [...prevState];
        });
    }, []);

    const onCloseToast = (index: number) => {
        const list = [...toastMessages];
        list.splice(index, 1);
        setToastMessages(list);
    };


    return (
        <React.Fragment>
            <Suspense fallback={loading()}>
                <div className="wrapper">
                    <Suspense fallback={loading()}>
                        <LeftSidebar hideLogo={false}/>
                    </Suspense>

                    <div className="content-page">

                        <Suspense fallback={loading()}>
                            <Topbar/>
                        </Suspense>

                        <div className="content">
                            <Container fluid>
                                <Suspense fallback={loading()}>
                                    <AppContext.Provider
                                        value={
                                            {
                                                showToast: showToastMessage
                                            }
                                        }>
                                        <Outlet></Outlet>
                                    </AppContext.Provider>
                                </Suspense>
                            </Container>
                        </div>

                        <Suspense fallback={loading()}>
                            <Footer/>
                        </Suspense>
                    </div>

                    <ToastContainer
                        position="bottom-end"
                        className="mb-3 p-3 position-fixed">
                        {toastMessages.map((item: ToastNotification, index) => {
                            return (
                                <Toast
                                    show={true}
                                    key={index}
                                    onClose={() => onCloseToast(index)}
                                    delay={5000}
                                    autohide>
                                    <Toast.Header className={`bg-${item.type} text-white`}>
                                        <span className="material-symbols-outlined">info</span>
                                        <strong className="ms-2 me-auto">Notification Message</strong>
                                    </Toast.Header>
                                    <Toast.Body className="bg-white">{item.message}</Toast.Body>
                                </Toast>
                            );
                        })}
                    </ToastContainer>
                </div>
            </Suspense>
        </React.Fragment>
    );
}

export default RootLayout;