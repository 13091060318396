import React, { useEffect } from "react";
import PageTitle from '../components/PageTitle';
import { useNavigate } from 'react-router-dom';

function HomePage() {
  // TODO Will implement dashboard

  // Send use to Cases list page
  let navigate = useNavigate();
  useEffect(() => {
      navigate("/cases");
  }, []);

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          {label: "Dashboard", path: "/home/", active: true},
        ]}
        title={"Dashboard"}
      />
    </React.Fragment>
  );
}

export default HomePage;