import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import React from "react";
import { CameraMedia } from "./interfaces";
import { Link } from "react-router-dom";

export const getDisplayDate = (dt: string) => {
    return new Date(dt.split('T')[0].replace(/-/g, "/")).toDateString();
}

export const getLocaleDateString = (dt: string) => {
    console.log(navigator.language)
    return new Date(dt).toLocaleDateString(navigator.language,{
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
    });
}

export const loading = () => {
    return (
        <div className="d-flex justify-content-center">
            <Spinner className="text-primary m-2"/>
        </div>
    );
}

export const roundOff = (n: number, decimalPlaces = 3) => {
    if (!n) {
        return 0;
    }
    return Number(n.toFixed(decimalPlaces));
}

export const calculateAbsoluteROIValues = (roiRect: any, imageWidth: number, imageHeight: number) => {
    return {
        roiX: Math.floor(roiRect.relative_x * imageWidth),
        roiY: Math.floor(roiRect.relative_y * imageHeight),
        roiWidth: Math.floor(roiRect.relative_width * imageWidth),
        roiHeight: Math.floor(roiRect.relative_height * imageHeight)
    }
}

export const calculateLineWidth = (multiplier: number) => {
    return Math.ceil(multiplier * 0.005);
}

export const roiColor = '#16b4c2';


export const getTimeIn12h = (date: string) => {
    return new Date(date).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
}

export const getWeekDay = (date: string) => {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    const day = new Date(date).getDay()
    return days[day];
}

export const getImageFilename = (image: CameraMedia) => {
    let filename = image.filename;
    if (image.hd_file_path !== null) {
        filename = filename.replace('sd', 'hd');
    }
    return filename;
};

export const CustomLink = ({id, className, children, onClick, title, to, ...rest}: any) => (
    <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
        <Link to={to} className={className} onClick={onClick} target={rest["target"]} download={rest["download"]}>{children}</Link>
    </OverlayTrigger>
)
