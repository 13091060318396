import { BackendApi } from "./backend-api";
import { GetUsersResponse, User } from "../helpers/interfaces";

export default class UserApi {
    private backendApi: BackendApi;

    constructor() {
        this.backendApi = BackendApi.Instance;
    }

    async listUsers(args?: any): Promise<GetUsersResponse> {
        return this.backendApi.getApiResponse({
            endPoint: '/users',
            queryStringParams: args
        });
    }

    async addUser(user: User) {
        return this.backendApi.getApiResponse({
            endPoint: `/users`,
            method: "POST",
            data: user
        })
    }

    async updateUser(user: any): Promise<User> {
        return this.backendApi.getApiResponse({
            endPoint: `/users/${user.id}`,
            method: "PUT",
            data: user
        })
    }

    async deleteUser(userId: number) {
        return this.backendApi.getApiResponse({
            endPoint: `/users/${userId}`,
            method: "DELETE"
        })
    }

    async getUser(userId: number): Promise<User> {
        return this.backendApi.getApiResponse({
            endPoint: `/users/${userId}`
        });
    }
}