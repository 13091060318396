import React, { useCallback, useContext, useEffect, useState } from 'react'
import ListPagination from "components/ListPagination";
import { Row, Spinner } from "react-bootstrap";
import ImageGallery from "components/ImageGallery";
import CaseApi from "api/case-api";
import { CaseImagesContext } from "pages/cases/view/case-images/CaseImages";

const caseApi = new CaseApi()

const HDImages = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(48);
    const [totalPages, setTotalPages] = useState(0);
    const [gallery, setGallery] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState(false);
    const {
        captureDate, 
        caseInfo, 
        locationInfo, 
        onGoToImage
    } = useContext(CaseImagesContext)

    // Fetch HD images
    useEffect(() => {
        const getHDImages = async () => {
            try {
                setIsLoading(true);
                const offset = (currentPage - 1) * pageSize;
                const {images, count} = await caseApi.getLocationImages(
                    caseInfo.id, locationInfo.id, {
                        capture_date: captureDate,
                        filter_hd: true,
                        limit: pageSize,
                        offset: offset
                    });
                setGallery([...images]);
                setTotalPages(Math.ceil(count / pageSize));
            } catch (e) {
                console.error(e)
            } finally {
                setIsLoading(false);
            }
        }
        getHDImages();
    }, [currentPage, pageSize, captureDate, caseInfo.id, locationInfo.id]);

    const onSetPageSize = useCallback((size: number) => {
        setPageSize(size);
    }, []);

    const onChangePage = useCallback((page: number) => {
        setCurrentPage(page);
    }, []);

    return (
        <React.Fragment>

            <Row className="filterable-content position-relative">
                {isLoading &&
                    <div className="d-flex justify-content-center">
                        <Spinner className="text-primary m-2"/>
                    </div>
                }
                {
                    !isLoading && gallery.length === 0 &&
                    <span className="text-muted">No HD images found</span>
                }
                <ImageGallery
                    name="HD"
                    images={gallery}/>
            </Row>

            <ListPagination
                className="pagination-bottom"
                pageSize={pageSize}
                currentPage={currentPage}
                totalPages={totalPages}
                onSetPageSize={onSetPageSize}
                onChangePage={onChangePage}
                onGoToImage={onGoToImage}
            />
        </React.Fragment>
    );
}

export default React.memo(HDImages);