import React from "react"
import ProfileDropdown from '../ProfileDropdown';
import profilePic from "../../assets/images/user-profile.jpeg";
import { useLoaderData } from "react-router-dom";

const ProfileMenus = [
  {
    label: "My Account",
    icon: "person",
    redirectTo: "#",
  },
  {
    label: "Logout",
    icon: "logout",
    redirectTo: "/logout",
  },
];


const Topbar = (props: any) => {
    const authData: any = useLoaderData();
    const fullname = `${authData.firstname} ${authData.lastname}`
    return (
        <div className="navbar-custom">
            <div className="topbar">
                <div className="topbar-menu d-flex align-items-center gap-1">
                </div>
                <ul className="topbar-menu d-flex align-items-center">
                    <li className="dropdown">
                        <ProfileDropdown
                            profilePic={profilePic}
                            menuItems={ProfileMenus}
                            username={fullname}
                            userTitle={"O"}
                        />
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Topbar;
