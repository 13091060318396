import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { User } from "../../../helpers/interfaces";
import Table from "../../../components/Table";
import { Button, Spinner } from "react-bootstrap";
import AssignUsersFormModal from "./AssignUsersFormModal";
import UnassignUserModal from "./UnassignUserModal";
import CaseApi from "../../../api/case-api";

type IProps = {
    caseId: number;
}
const caseApi = new CaseApi();

const CaseUsersTable = ({caseId, ...props}: IProps) => {
    const [users, setUsers] = useState<User[]>([]);
    const [isLoading, setIsLoading] = useState(false)
    const [isSaving, setIsSaving] = useState(false);
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState<User>();

    useEffect(() => {
        async function fetchUsers() {
            setIsLoading(true);
            setUsers([]);
            try {
                const {users} = await caseApi.listCaseUsers(caseId, {
                    offset: 0,
                    limit: 1000
                });
                setUsers([...users]);
            } catch (e) {
                console.error('Error loading users');
            } finally {
                setIsLoading(false);
            }
        }

        fetchUsers();
    }, []);


    const columns = [
        {
            header: "First Name",
            accessorKey: "firstname",
        },
        {
            header: "Last Name",
            accessorKey: "lastname",
        },
        {
            header: "Email",
            accessorKey: "email",
        },
        {
            header: "Actions",
            accessorKey: "actions",
            cell: (prop: any) => ActionColumn(prop)
        }
    ];

    const ActionColumn = (prop: any) => {
        const _user = prop.row.original;
        return (
            <>
                <Link
                    to="#"
                    onClick={() => toggleRemoveUserModal(_user)}>
                    <span className="material-symbols-outlined action-icon text-danger">close</span>
                </Link>
            </>
        );
    };
    const toggleAssignModal = () => {
        setShowAssignModal(!showAssignModal);
    }
    const toggleRemoveUserModal = (user?: User) => {
        setSelectedUser(user)
        setShowRemoveUserModal(!showRemoveUserModal);
    }

    const saveSelectedUsers = async (selectedUserIds: number[]) => {
        try {
            setIsSaving(true);
            const resp = await caseApi.addCaseUsers(caseId, selectedUserIds);
            setUsers([...resp.users]);
        } catch (e) {
            console.error('Error loading users');
        } finally {
            setIsSaving(false);
            toggleAssignModal();
        }
    }

    const removeAssignedUser = async () => {
        if (!selectedUser) {
            console.error('No selected user')
            return;
        }
        try {
            setIsSaving(true);
            const resp = await caseApi.removeCaseUsers(caseId, [selectedUser.id])
            setUsers([...resp.users]);
        } catch (e) {
            console.error('Error loading users');
        } finally {
            setIsSaving(false);
            toggleRemoveUserModal();
            setSelectedUser(undefined);
        }
    }

    const AssignUserButton = () => {
        return (
            <React.Fragment>
                <Button
                    variant="primary"
                    className="btn-add"
                    onClick={() => toggleAssignModal()}>
                    Add User
                </Button>
            </React.Fragment>
        );
    }
    return (
        <React.Fragment>
            <Table
                classNames={"table-striped"}
                actionButton={AssignUserButton}
                isSearchable={true}
                columns={columns}
                data={users}
            />
            {isLoading &&
                <div className="d-flex justify-content-center">
                    <Spinner className="text-primary m-2"/>
                </div>
            }
            {!isLoading && users.length === 0 && <span className="text-muted">No assigned users</span>}
            <AssignUsersFormModal
                isSaving={isLoading}
                onSaveSelectedUsers={saveSelectedUsers}
                show={showAssignModal}
                onHide={toggleAssignModal}
                caseId={caseId}/>

            <UnassignUserModal
                isSaving={isSaving}
                selectedUser={selectedUser}
                onRemoveUser={removeAssignedUser}
                show={showRemoveUserModal}
                onHide={toggleRemoveUserModal}/>
        </React.Fragment>
    );
}

export default CaseUsersTable;