import React, { useEffect, useState } from 'react';
import classNames from "classnames";
import { Link, useLoaderData } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import FileApi from "../api/file-api";
import { AuthUser } from "../helpers/interfaces";

const MenuItemWithChildren = ({activeMenuItems, item, toggleMenu, linkClassName, subMenuClassNames}: any) => {

    const [open, setOpen] = useState(
        activeMenuItems.includes(item.key)
    );

    useEffect(() => {
        setOpen(activeMenuItems.includes(item.key));
    }, [activeMenuItems, item]);

    const toggleMenuItem = () => {
        const status = !open;
        setOpen(status);
        if (toggleMenu) toggleMenu(item, status);
        return false;
    };
    return (
        <li className={classNames("menu-item", {"menuitem-active": open})}>
            <Link
                to="#"
                onClick={toggleMenuItem}
                data-menu-key={item.key}
                aria-expanded={open}
                className={classNames("menu-link", linkClassName, {
                    "menuitem-active": activeMenuItems.includes(item.key)
                        ? "active"
                        : "",
                })}
            >
                {item.icon && (
                    <span className="menu-icon">
                        <i className="material-symbols-outlined">{item.icon}</i>
                    </span>
                )}
                <span className="menu-text"> {item.label} </span>
                {!item.badge ? (
                    <span className="menu-arrow"></span>
                ) : (
                    <span
                        className={`badge bg-${item.badge.variant} rounded-pill ms-auto`}
                    >
            {item.badge.text}
          </span>
                )}
            </Link>
            <Collapse in={open}>
                <div>
                    <ul className={classNames(subMenuClassNames)}>
                        {(item.children || []).map((child: any, idx: number) => {
                            return (
                                <React.Fragment key={idx}>
                                    {child.visible && child.children ? (
                                        <>
                                            {/* parent */}
                                            <MenuItemWithChildren
                                                item={child}
                                                linkClassName={
                                                    activeMenuItems.includes(child.key) ? "active" : ""
                                                }
                                                activeMenuItems={activeMenuItems}
                                                subMenuClassNames="sub-menu"
                                                toggleMenu={toggleMenu}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            {/* child */}
                                            { child.visible && <MenuItem
                                                item={child}
                                                className={
                                                    activeMenuItems.includes(child.key)
                                                        ? "menuitem-active"
                                                        : ""
                                                }
                                                linkClassName={
                                                    activeMenuItems.includes(child.key) ? "active" : ""
                                                }
                                            />}
                                        </>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </ul>
                </div>
            </Collapse>
        </li>
    );

};

const MenuItem = ({item, className, linkClassName}: any) => {
    return (
        <li className={classNames("menu-item", className)}>
            <MenuItemLink item={item} className={linkClassName}/>
        </li>
    );
};

const MenuItemLink = ({item, className}: any) => {
    return (
        <Link
            to={item.url}
            download={item.downloadName}
            target={item.target}
            className={classNames("side-nav-link-ref menu-link", className)}
            data-menu-key={item.key}
        >
            {item.icon && (
                <span className="menu-icon">
                    <i className="material-symbols-outlined">{item.icon}</i>
                </span>
            )}
            <span className="menu-text"> {item.label} </span>
            {item.badge && (
                <span className={`badge bg-${item.badge.variant} `}>
          {item.badge.text}
        </span>
            )}
        </Link>
    );
};

const fileApi = new FileApi();

const AppMenu = () => {
    const appName = process.env.REACT_APP_MOBILE_APP_NAME
    const [activeMenuItems, setActiveMenuItems] = useState<string[]>([]);
    const [appDownloadURL, setAppDownloadURL] = useState("")
    const [menuItems, setMenuItems] = useState<any[]>([])
    const authUser = useLoaderData() as AuthUser;

    const downloadMobileApp = async () => {
        try {
            if (!appName) {
                return;
            }
            const blob = await fileApi.downloadFile(appName);
            const url = URL.createObjectURL(blob);
            setAppDownloadURL(url);
        } catch (e) {
        }
    }

    useEffect(() => {
        downloadMobileApp()
        setMenuItems(items)
    }, []);

    const items = [
        {
            key: "navigation",
            label: "Navigation",
            visible: true,
            isTitle: true
        },
        {
            key: "cases",
            label: "Cases",
            isTitle: false,
            visible: true,
            icon: "cases",
            children: [
                {
                    key: "view-cases",
                    label: "View Cases",
                    visible: true,
                    url: "/cases",
                    parentKey: "cases",
                },
                {
                    key: "manage-cases",
                    visible: authUser && authUser.is_admin,
                    label: "Manage Cases",
                    url: "/cases/manage",
                    parentKey: "cases",
                }
            ]
        },
        {
            key: "users",
            label: "Users",
            isTitle: false,
            icon: "group",
            visible: authUser && authUser.is_admin,
            url: "/users",
        },
        {
            key: "cameras",
            label: "Cameras",
            isTitle: false,
            visible: authUser && authUser.is_admin,
            icon: "camera",
            url: "/cameras",
        }
    ];

    const bottomMenuItems = [
        {
            key: "help",
            label: "Download APK",
            icon: "apk_install",
            target: "_blank",
            downloadName: appName,
            url: appDownloadURL,
        },
        {
            key: "help",
            label: "Help Center",
            icon: "help",
            url: "#"
        }
    ]

    return (
        <React.Fragment>
            <ul className="menu" id="main-side-menu">
                {
                    menuItems.map((item, idx) => {
                        return (
                            <React.Fragment key={idx}>
                                {
                                    item.visible && item.isTitle ? (
                                        <li
                                            className={classNames("menu-title", {
                                                "mt-2": idx !== 0,
                                            })}
                                        >
                                            {item.label}
                                        </li>
                                    ) : (
                                        <>
                                            {item.children ? (
                                               item.visible && <MenuItemWithChildren
                                                    item={item}
                                                    subMenuClassNames="sub-menu"
                                                    activeMenuItems={activeMenuItems}
                                                    linkClassName="menu-link"
                                                />
                                            ) : ( item.visible &&
                                                <MenuItem
                                                    item={item}
                                                    linkClassName="menu-link"
                                                    className={
                                                        activeMenuItems.includes(item.key)
                                                            ? "menuitem-active"
                                                            : ""
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                            </React.Fragment>
                        );
                    })
                }
            </ul>
            <ul className="menu menu-link-bottom">
                {
                    bottomMenuItems.map((item, idx) => {
                        return (
                            <MenuItem
                                key={idx}
                                linkClassName="menu-link"
                                download={item.downloadName}
                                item={item}
                                className={classNames(
                                    {'menuitem-active': activeMenuItems.includes(item.key)}
                                )}
                            />

                        );
                    })
                }
            </ul>
        </React.Fragment>
    );
};

export default AppMenu;