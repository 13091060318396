import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { User } from "../../helpers/interfaces";


const validateEmail = (email: string) => {
    const reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
    return reg.test(email);
}
const getDialogTitle = (mode: string) => {
    if (mode === "edit") {
        return "Edit User";
    }
    if (mode === "updatePassword") {
        return "Update Password"
    }
    return "Add User"
}

const DefaultUserInfo: User = {
    id: 0,
    firstname: '',
    lastname: '',
    password: '',
    confirm_password: '',
    email: '',
    is_admin: false,
    created_by: 0,
    created_at: ''
}

const UserFormModal = (props: any) => {
    const [userInfo, setUserInfo] = useState<User>(DefaultUserInfo)
    const [emailInputTouched, setEmailInputTouched] = useState(false)
    const [formError, setFormError] = useState({})
    const [firstnameInputTouched, setFirstnameInputTouched] = useState(false)
    const [lastnameInputTouched, setLastnameInputTouched] = useState(false);
    const [passwordInputTouched, setPasswordInputTouched] = useState(false);
    const [confirmPasswordInputTouched, setConfirmPasswordInputTouched] = useState(false)

    const emailIsValid = validateEmail(userInfo.email);
    const firstnameIsValid = userInfo.firstname.trim() !== '';
    const lastnameIsValid = userInfo.lastname.trim() !== '';
    const passwordIsValid = userInfo.password && userInfo.password.trim().length > 5;
    const confirmPasswordIsValid = passwordIsValid && userInfo?.confirm_password === userInfo?.password;


    const handleInputChange = (event: any) => {
        resetFormErrors();
        let value = event.target.value;
        if (event.target.type === "checkbox") {
            value = event.target.checked;
        }
        setUserInfo((prevState: User) => {
            return {
                ...prevState,
                [event.target.name]: value
            }
        });
    }

    useEffect(() => {
        let userInfo = props.userInfo;
        if (userInfo === undefined) {
            userInfo = DefaultUserInfo;
        }
        setUserInfo(userInfo);
        resetFormErrors();
    }, [props.userInfo, props.show]);

    const resetFormErrors = () => {
        setEmailInputTouched(false);
        setFirstnameInputTouched(false);
        setLastnameInputTouched(false);
        setPasswordInputTouched(false);
        setConfirmPasswordInputTouched(false);
        setFormError({});
    }

    const onInputTouched = (input: string) => {
        if (input === 'email') {
            setEmailInputTouched(true)
        }
        if (input === 'firstname') {
            setFirstnameInputTouched(true)
        }
        if (input === 'lastname') {
            setLastnameInputTouched(true)
        }

        if (input === 'password') {
            setPasswordInputTouched(true)
        }
        if (input === 'confirm_password') {
            setConfirmPasswordInputTouched(true)
        }
    }

    function onFormSubmit(event: any) {
        event.preventDefault();
        setFirstnameInputTouched(true);
        setLastnameInputTouched(true);
        setEmailInputTouched(true);
        setPasswordInputTouched(true);
        setConfirmPasswordInputTouched(true);

        if (["add", "edit"].includes(props.action)) {
            if (!firstnameIsValid) {
                return;
            }
            if (!lastnameIsValid) {
                return;
            }
            if (!emailIsValid) {
                return;
            }
        }

        if (["add", "updatePassword"].includes(props.action)) {
            if (!passwordIsValid) {
                return;
            }
            if (!confirmPasswordIsValid) {
                return;
            }
        }
        props.onSave(userInfo)
    }

    return (<React.Fragment>
        <Modal
            show={props.show}
            onHide={props.onHide}
            dialogClassName="modal-dialog-centered">
            <Modal.Header
                onHide={props.onHide} closeButton>
                <h4 className="modal-title">{getDialogTitle(props.action)}
                </h4>
            </Modal.Header>
            <Modal.Body>
                {["add", "edit"].includes(props.action) &&
                    <React.Fragment>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="firstname">
                                <Form.Label className="text-bold">First Name *</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    name="firstname"
                                    isValid={firstnameInputTouched && firstnameIsValid}
                                    isInvalid={firstnameInputTouched && !firstnameIsValid}
                                    maxLength={150}
                                    value={userInfo.firstname}
                                    onBlur={() => onInputTouched('firstname')}
                                    onChange={handleInputChange}/>
                                <Form.Control.Feedback type="invalid">
                                    Please enter valid Firstname
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} controlId="lastname">
                                <Form.Label className="text-bold">Last Name *</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    name="lastname"
                                    isValid={lastnameInputTouched && lastnameIsValid}
                                    isInvalid={lastnameInputTouched && !lastnameIsValid}
                                    maxLength={150}
                                    value={userInfo.lastname}
                                    onBlur={() => onInputTouched('lastname')}
                                    onChange={handleInputChange}/>
                                <Form.Control.Feedback type="invalid">
                                    Please enter valid Lastname
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label className="text-bold">Email Address *</Form.Label>
                            <Form.Control
                                type="email"
                                required
                                name="email"
                                isValid={emailInputTouched && emailIsValid}
                                isInvalid={emailInputTouched && !emailIsValid}
                                maxLength={150}
                                onBlur={() => onInputTouched('email')}
                                onChange={handleInputChange}
                                value={userInfo.email}>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please enter valid email
                            </Form.Control.Feedback>
                        </Form.Group>
                    </React.Fragment>}
                {["add", "updatePassword"].includes(props.action) && (
                    <React.Fragment>
                        <Form.Group className="mb-3" controlId="password">
                            <Form.Label className="text-bold">Password *</Form.Label>
                            <Form.Control
                                type="password"
                                required
                                name="password"
                                isValid={passwordInputTouched && passwordIsValid}
                                isInvalid={passwordInputTouched && !passwordIsValid}
                                maxLength={150}
                                onBlur={() => onInputTouched('password')}
                                onChange={handleInputChange}
                                value={userInfo.password || ""}>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please enter valid password
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="confirm_password">
                            <Form.Label className="text-bold">Confirm Password *</Form.Label>
                            <Form.Control
                                type="password"
                                required
                                name="confirm_password"
                                isValid={confirmPasswordInputTouched && confirmPasswordIsValid}
                                isInvalid={confirmPasswordInputTouched && !confirmPasswordIsValid}
                                maxLength={150}
                                onBlur={() => onInputTouched('confirm_password')}
                                onChange={handleInputChange}
                                value={userInfo.confirm_password || ""}>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Password is invalid or does not match
                            </Form.Control.Feedback>
                        </Form.Group>
                    </React.Fragment>)}
                {["add", "edit"].includes(props.action) &&
                    <Form.Group controlId="isAdmin">
                        <Form.Check
                            className="text-bold"
                            name="is_admin"
                            type="checkbox"
                            onChange={handleInputChange}
                            checked={userInfo.is_admin}
                            label="Admin"
                            id="isAdmin"
                        />
                    </Form.Group>}
            </Modal.Body>
            <Modal.Footer>
                {props.isSaving && <Spinner className="m-2"/>}
                <Button
                    disabled={props.isSaving}
                    onClick={onFormSubmit}
                    variant="primary"
                    type="submit">
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    </React.Fragment>);
}

UserFormModal.defaultProps = {
    action: "add",
}

export default UserFormModal;