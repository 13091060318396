import React, { useEffect, useMemo, useState } from 'react';
import PageTitle from '../../components/PageTitle';
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import UserApi from "../../api/user-api";
import { AuthUser, User } from "../../helpers/interfaces";
import { Link, useLoaderData } from "react-router-dom";
import Table from "../../components/Table";
import UserFormModal from "./UserFormModal";
import DeleteUserModal from "./DeleteUserModal";

const userApi = new UserApi();

const UsersPage = (props: any) => {
    const authUser = useLoaderData() as AuthUser;

    const [isLoading, setIsLoading] = useState(false);
    const [modalAction, setModalAction] = useState("add");
    const [showUserFormModal, setShowUserFormModal] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<User>();
    const [formError, setFormError] = useState({});
    const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);

    const columns = useMemo(() => [
        {
            header: "First Name",
            accessorKey: "firstname",
        },
        {
            header: "Last Name",
            accessorKey: "lastname",
        },
        {
            header: "Email",
            accessorKey: "email",
        },
        {
            header: 'Is Admin',
            accessorKey: 'is_admin',
            cell: (prop: any) => prop.getValue() === true ? <span className="text-primary">Yes</span> : 'No'
        },
        {
            header: "Date Added",
            accessorKey: "created_at",
            sort: true,
            cell: (prop: any) => new Date(prop.getValue()).toISOString().split('T')[0],
        },
        {
            header: "Actions",
            accessorKey: "actions",
            cell: (prop: any) => ActionColumn(prop)
        }
    ], []);

    const ActionColumn = (prop: any) => {
        const _user = prop.row.original;
        return (
            <>
                <Link
                    to="#"
                    onClick={() => toggleUserModal("edit", _user)}>
                    {" "}
                    <span className="material-symbols-outlined action-icon">edit</span>
                </Link>
                {authUser.id !== _user.id && <Link to="#" onClick={() => toggleDeleteModal(_user)}>
                    {" "}
                    <span className="material-symbols-outlined action-icon action-delete">delete</span>
                </Link>}
                <Link
                    to="#"
                    onClick={() => toggleUserModal("updatePassword", _user)}>
                    <span className="material-symbols-outlined action-icon">key</span>
                </Link>
            </>
        );
    };

    // Fetch Assigned users
    useEffect(() => {
        async function fetchUsers() {
            setIsLoading(true);
            setUsers([]);
            try {
                const resp = await userApi.listUsers({
                    offset: 0,
                    limit: 1000
                });
                setUsers([...resp.users]);
            } catch (e) {
                console.error('Error loading users');
            } finally {
                setIsLoading(false)
            }
        }

        fetchUsers();
    }, []);

    const toggleUserModal = (action = "add", user?: User) => {
        setSelectedUser(user);
        setModalAction(action);
        setShowUserFormModal(!showUserFormModal);
    }

    const toggleDeleteModal = (selectedUser?: User) => {
        setSelectedUser(selectedUser);
        setShowDeleteUserModal(!showDeleteUserModal);

    }

    const addUser = async (user: User) => {
        try {
            setIsSaving(true);
            const resp = await userApi.addUser(user);
            users.push(resp);
            setUsers([...users]);
        } catch (e) {
            console.error('Error saving user')
        } finally {
            toggleUserModal();
            setIsSaving(false);
        }
    }

    const deleteUser = async () => {
        if (selectedUser === undefined) {
            console.log('Error deleting user')
            return
        }
        try {
            setIsSaving(true);
            await userApi.deleteUser(selectedUser.id);
            // Update table
            const idx = users.findIndex(usr => usr.id === selectedUser.id);
            users.splice(idx, 1);
            setUsers([...users]);
        } catch (e) {
            console.error("Error saving camera", e)
        } finally {
            setIsSaving(false);
            toggleDeleteModal();
        }
    }

    const updateUser = async (userData: any) => {
        try {
            setIsSaving(true);
            const updatedUser = await userApi.updateUser(userData);
            // update table
            const idx = users.findIndex((item: any) => item.id === userData.id);
            users[idx] = updatedUser
            setUsers([...users])
            if (updatedUser.id === authUser.id) {
                authUser.firstname = updatedUser.firstname;
                authUser.lastname = updatedUser.lastname;
                localStorage.setItem("authUser", JSON.stringify(authUser));
            }
            toggleUserModal();
        } catch (e: any) {
            setFormError(e)
        } finally {
            setIsSaving(false);
        }
    }

    const onSave = async (user: any) => {
        if (modalAction === "add") {
            return addUser(user)
        }

        if (modalAction === "edit") {
            delete user.password;
            delete user.confirm_password;
            return updateUser(user)
        }

        if (modalAction === "updatePassword") {
            return updateUser({
                "id": user.id,
                "password": user.password,
                "confirm_password": user.confirm_password
            })
        }
    }

    const AddUserButton = () => {
        return (
            <React.Fragment>
                <Button
                    variant="primary"
                    className="btn-add"
                    onClick={() => toggleUserModal("add")}>
                    Add User
                </Button>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <PageTitle
                breadCrumbItems={[
                    {label: "Users", path: "/users", active: true},
                ]}
                title={"Users"}
            />
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Table
                                classNames="table-striped"
                                actionButton={AddUserButton}
                                isSearchable={true}
                                columns={columns}
                                data={users}
                            />
                            {!isLoading && users.length === 0 &&
                                <span>No users found</span>
                            }
                            {isLoading &&
                                <div className="d-flex justify-content-center">
                                    <div>
                                        <Spinner className="text-primary m-2"/>
                                    </div>
                                </div>
                            }
                            <UserFormModal
                                action={modalAction}
                                onSave={onSave}
                                userInfo={selectedUser}
                                isSaving={isSaving}
                                show={showUserFormModal}
                                onHide={toggleUserModal}/>

                            <DeleteUserModal
                                isSaving={isSaving}
                                userToDelete={selectedUser || {}}
                                show={showDeleteUserModal}
                                onHide={toggleDeleteModal}
                                onDelete={deleteUser}
                            />
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
        </React.Fragment>
    )
}

export default UsersPage
