import React from 'react';
import { Link } from 'react-router-dom';
import logoSm from "../../assets/images/logo-sm.jpg";
import logoDark from "../../assets/images/logo-dark.jpg";
import logoLight from "../../assets/images/logo-light.jpg";
import AppMenu from '../AppMenu';


/* sidebar content */
const SideBarContent = () => {
  return (
    <React.Fragment>
      <AppMenu/>
      <div className="clearfix"/>
    </React.Fragment>
  );
};

function LeftSidebar({hideLogo}) {
  return (
    <React.Fragment>
      <div className="app-menu">
        {!hideLogo && (
          <div className="logo-box">
            <Link to="/" className="logo logo-dark text-center">
              <span className="logo-lg">
                <img
                  src={logoDark}
                  alt=""
                  height="36"
                />
              </span>
            </Link>
          </div>
        )}
        <SideBarContent/>
      </div>
    </React.Fragment>
  );
}

export default LeftSidebar;