import { BackendApi } from "./backend-api";
import { AuthUser } from "../helpers/interfaces";

export default class AuthApi {
    private backendApi: BackendApi;

    constructor() {
        this.backendApi = BackendApi.Instance;
    }

    async login(email: string, password: string): Promise<AuthUser> {
        const includeAuthToken = false
        return this.backendApi.getApiResponse(
            {
                endPoint: "/login",
                method: "POST",
                data: {
                    email: email,
                    password: password
                }
            }, includeAuthToken
        )
    }

    async logout() {
        return this.backendApi.getApiResponse({
            endPoint: '/logout',
            method: 'POST'
        })
    }
}