import React, { useEffect, useState } from "react";
import { Case } from "../../../helpers/interfaces";
import CaseApi from "../../../api/case-api";
import { useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../../components/PageTitle";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import LocationsTable from "./LocationsTable";
import CaseUsersTable from "./CaseUsersTable";

const caseApi = new CaseApi();

const CaseDetail = (props: any) => {
    const [isLoading, setIsLoading] = useState(false)
    const [Case, setCase] = useState<Case>()
    const params = useParams();
    let navigate = useNavigate();

    const caseId = Number(params.caseId)

    useEffect(() => {
        async function fetchData(caseId: number) {
            setIsLoading(true);
            try {
                const _case = await caseApi.fetchCase(caseId)
                setCase(_case);


            } catch (e) {
                console.log("Error fetching data", e);
            } finally {
                setIsLoading(false);
            }
        }

        fetchData(caseId);
    }, []);


    const getFormattedDate = (date: any) => {
        if (!date) {
            return;
        }
        return new Date(date).toDateString()
    };

    return (
        <React.Fragment>
            <PageTitle
                breadCrumbItems={[
                    {label: "Cases", path: "/cases"},
                    {label: "Manage Cases", path: "/cases/manage"},
                    {label: `${Case?.name}`, active: true},
                ]}
                title="Case Detail"
            />
            <Row>
                <Col sm={12}>
                    <Card>
                        <Card.Body>
                            {isLoading &&
                                <div className="d-flex justify-content-center">
                                    <Spinner className="text-primary m-2"/>
                                </div>
                            }
                            {!isLoading && <React.Fragment>
                                <Row>
                                    <Col md={6}>
                                        <h4 className="header-title mb-3">{Case?.name}</h4>
                                        <p>{Case?.description}</p>
                                        <p>
                                        <span className="fw-semibold me-2">
                                            Date Created:
                                        </span>
                                            {getFormattedDate(Case?.created_at)}
                                        </p>
                                    </Col>
                                    <Col md={6} className="">
                                        <div className="d-flex align-items-center justify-content-end">
                                            <Button
                                                variant="primary"
                                                onClick={() => navigate(`/cases/${caseId}/locations`)}>
                                                Go to Case
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </React.Fragment>}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col sm={12} lg={6}>
                    <Card>
                        <Card.Body>
                            <LocationsTable caseId={caseId}/>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} lg={6}>
                    <Card>
                        <Card.Body>
                            <CaseUsersTable
                                caseId={caseId}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>);
}

export default CaseDetail;