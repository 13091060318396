import React from "react";

const Videos = () => {
    return (
        <React.Fragment>
            <span className="text-muted">No records found</span>
        </React.Fragment>
    );
}

export default React.memo(Videos);