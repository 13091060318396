import React, { useEffect, useState } from 'react';
import AuthLayout from "../components/layout/AuthLayout";
import { Button, Form, Spinner } from "react-bootstrap";
import classNames from "classnames";
import AuthApi from "../api/auth-api";
import { useNavigate, useNavigation } from "react-router-dom";


const authApi = new AuthApi();

const LoginPage = (props: any) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isValidated, setIsValidated] = useState(false);
    const [loginError, setLoginError] = useState("");
    const navigate = useNavigate();
    const navigation = useNavigation();

    const isSubmitting = navigation.state === "submitting";
    const emailIsValid = email && email.trim() !== '';
    const passwordIsValid = password && password.trim() !== '';


    useEffect(() => {
        clearForm();
        setIsValidated(false);
    }, [loginError]);

    const clearForm = () => {
        setPassword("");
        setEmail("")
    }

    const handleOnChange = (event: any) => {
        const inputName = event.target.name;
        const value = event.target.value;
        if (inputName === "email") {
            setEmail(value)
        }
        if (inputName === "password") {
            setPassword(value)
        }
        setIsValidated(false);
        // setLoginError("");
    }

    const onsubmitForm = async (event: any) => {
        setLoginError("");
        setIsValidated(true);
        event.preventDefault();

        if (!emailIsValid) {
            return;
        }

        if (!passwordIsValid) {
            return;
        }

        await login();
    }

       const login = async () => {
        try {
            const authUser = await authApi.login(email, password);
            localStorage.setItem("authUser", JSON.stringify(authUser));
            navigate('/');
        } catch (e: any) {
            console.log("Error login in", e);
            
            if (e.data && e.data.error) {
                setLoginError(e.data.error);
            } else {
                setLoginError("An unknown error occurred during login.");
            }
        }
    }
    

    return (
        <AuthLayout bottomLinks={undefined}>
            <h4 className="mt-0">Login</h4>
            <p className="text-muted mb-4">
                Enter your email address and password to access your account.
            </p>
            {loginError && <p className="text-danger">{loginError}</p>}
            {isSubmitting && <Spinner className="text-primary m-2"/>}
            <Form onSubmit={onsubmitForm}
                  className={classNames({"was-validated": isValidated})}
                  noValidate>
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="email">Email</Form.Label>
                    <Form.Control
                        id="email"
                        disabled={isSubmitting}
                        placeholder="Enter your email"
                        type="email"
                        onChange={handleOnChange}
                        value={email}
                        name="email"
                        required/>
                    <Form.Control.Feedback type="invalid">
                        Please enter valid email
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="password">Password</Form.Label>
                    <Form.Control
                        id="password"
                        disabled={isSubmitting}
                        type="password"
                        onChange={handleOnChange}
                        value={password}
                        name="password"
                        required/>
                    <Form.Control.Feedback type="invalid">
                        Password is required
                    </Form.Control.Feedback>
                </Form.Group>
                <div className="d-grid mb-0 text-center">
                    <Button
                        disabled={isSubmitting}
                        variant="primary"
                        type="submit">
                        Login
                    </Button>
                </div>
            </Form>

        </AuthLayout>
    );
}

export default LoginPage;