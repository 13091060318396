import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";

type IProps = {
    title?: string
    onCancel: () => void;
    onHide?: () => void;
    onConfirm: () => void;
    actionInProgress?: boolean;
    show: boolean;
    confirmBtnText?: string;
    cancelButtonText?: string;
    confirmBtnVariant?: string;
    [key: string]: any
}

export const ConfirmDialogBox = (props: IProps) => {

    const dialogTitle = props.title || "Confirm";
    const confirmBtnVariant = props.confirmBtnVariant || "primary";
    const confirmBtnText = props.cancelButtonText || "Confirm";
    const cancelButtonText = props.cancelButtonText || "Cancel";

    return (
        <React.Fragment>
            <Modal show={props.show}
                   onHide={props.onHide}
                   dialogClassName="modal-dialog-centered">
                <Modal.Header
                    onHide={props.onHide} closeButton>
                    <h4 className="modal-title">
                        {dialogTitle}
                    </h4>
                </Modal.Header>
                <Modal.Body>
                    {props.children}
                </Modal.Body>
                <Modal.Footer>
                    {props.actionInProgress && <Spinner className="m-2"/>}
                    <Button
                        disabled={props.actionInProgress}
                        onClick={props.onCancel} variant="secondary">
                        {cancelButtonText}
                    </Button>
                    <Button
                        disabled={props.actionInProgress}
                        onClick={props.onConfirm}
                        variant={confirmBtnVariant}>
                        {confirmBtnText}
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}