import React, { useCallback, useContext, useEffect, useState, useRef } from 'react'
import SelectApi from "api/select-api";
import ListPagination from "components/ListPagination";
import { Row, Spinner, Button} from "react-bootstrap";
import ImageGallery from "components/ImageGallery";
import { CaseImagesContext } from "./CaseImages";
import { createPortal } from "react-dom";
import CameraMediaApi from "api/camera-media-api";
import { GalleryItem } from "helpers/types";

const selectApi = new SelectApi()
const cameraMediaApi = new CameraMediaApi()

const SelectImages = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(48);
    const [totalPages, setTotalPages] = useState(0);
    const [gallery, setGallery] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState(false);
    const pageImages = useRef<GalleryItem[]>([]);
    //const [pageImages, setPageImages] = useState<GalleryItem[]>([]);
    const {
        selectJob,
        contextMenuContainer,
        lockedImageIds,
        setLockedImageIds,
        imageRequestHD,
        onGoToImage
    } = useContext(CaseImagesContext);
    // Fetch select images
    useEffect(() => {
        const getSelectImages = async () => {
            if (!selectJob.id) {
                return;
            }
            try {
                setIsLoading(true);
                const offset = (currentPage - 1) * pageSize;
                const {images, count} = await selectApi.fetchSelectImages(
                    selectJob.id,
                    {
                        limit: pageSize,
                        offset: offset
                    }
                );
                setGallery([...images]);
                pageImages.current = images.map( media => ({
                    ...media,
                    download_url: '',
                    image_src: ''
                }));
                setTotalPages(Math.ceil(count / pageSize));
            } finally {
                setIsLoading(false);
            }
        }
        getSelectImages();
    }, [currentPage, pageSize, selectJob]);

    const onSetPageSize = useCallback((size: number) => {
        setPageSize(size);
    }, []);

    const onChangePage = useCallback((page: number) => {
        setCurrentPage(page);
    }, []);


    const lockAllSelectImagesOnThisPage = async () => {
        let pageImageIds = pageImages.current.map( (imgObj: GalleryItem) => imgObj.id )
        try {
            let api_resp = await cameraMediaApi.markMediaListAsLocked(pageImageIds)
            if (!api_resp.hasOwnProperty('exit_code') || api_resp.exit_code !== 0){
                console.log('There was a problem locking all images on this page') 
                return;
            }
            let lockedImageIdsCopy = [...lockedImageIds];
            //merge pageImages w/ lockedImageIds so lockedImageIds includes ids in pageImages
            pageImageIds.forEach((element: number) => {
                if (lockedImageIdsCopy.includes(element)) {
                    return;
                }
                lockedImageIdsCopy.push(element)
            });
            setLockedImageIds(lockedImageIdsCopy)
        } catch (e) {
                console.error(e)
        }
    }
    const unlockAllSelectImagesOnThisPage = async () => {
        let pageImageIds = pageImages.current.map( (imgObj: GalleryItem) => imgObj.id )
        try {
            let api_resp = await cameraMediaApi.markMediaListAsUnlocked(pageImageIds)
            if (!api_resp.hasOwnProperty('exit_code') || api_resp.exit_code !== 0){
                console.log('There was a problem locking all images on this page') 
                return;
            }
            let lockedImageIdsCopy = [...lockedImageIds];
            for (let i=0; i < pageImageIds.length; i++) {
                let current_img_id = pageImageIds[i];
                if (lockedImageIdsCopy.includes(current_img_id)){
                    let index_of_id_to_remove = lockedImageIdsCopy.indexOf(current_img_id)
                    lockedImageIdsCopy.splice(index_of_id_to_remove, 1)
                }
            }

            setLockedImageIds(lockedImageIdsCopy)
        } catch (e) {
            console.error(e)
        }
    }

    const requestAllHdImagesOnThisPage = async () => {
        gallery.map( (image, idx) => {
            imageRequestHD(idx, image.id, gallery, setGallery, cameraMediaApi);
        })
    }

    return (
        <React.Fragment>
            {
                contextMenuContainer?.current &&
                createPortal(
                    <React.Fragment>
                        {
                            <Button className="filter-menu-item top-toolbar-button lock-status-button lock-all"
                            onClick={ () => lockAllSelectImagesOnThisPage() }>
                                Lock All
                            </Button>
                        }
                    </React.Fragment>,
                    contextMenuContainer.current,
                    "lock-all-images-button"
                )  
            }

            {
                contextMenuContainer?.current &&
                createPortal(
                    <React.Fragment>
                        {
                            <Button className="filter-menu-item top-toolbar-button lock-status-button unlock-all"
                            onClick={ () => unlockAllSelectImagesOnThisPage() }>
                                Unlock All
                            </Button>
                        }
                    </React.Fragment>,
                    contextMenuContainer.current,
                    "unlock-all-images-button"
                )  
            }

            {
                contextMenuContainer?.current &&
                createPortal(
                    <React.Fragment>
                        {
                            <Button className="filter-menu-item top-toolbar-button request-all-hd"
                            onClick={ () => requestAllHdImagesOnThisPage() }>
                                All HD
                            </Button>
                        }
                    </React.Fragment>,
                    contextMenuContainer.current,
                    "request-all-hd-button"
                )  
            }

            <Row className="filterable-content position-relative">
                {isLoading &&
                    <div className="d-flex justify-content-center">
                        <Spinner className="text-primary m-2"/>
                    </div>
                }
                {
                    !isLoading && gallery.length === 0 &&
                    <span className="text-muted">No select images found</span>
                }
                <ImageGallery
                    name="Select"
                    images={gallery}/>
            </Row>

            <ListPagination
                className="pagination-bottom"
                pageSize={pageSize}
                currentPage={currentPage}
                totalPages={totalPages}
                onSetPageSize={onSetPageSize}
                onChangePage={onChangePage}
                onGoToImage={onGoToImage}    
            />
        </React.Fragment>
    );
}

export default React.memo(SelectImages);