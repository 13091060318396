interface BaseObj {
    readonly id: number;
    readonly created_at: string;
    readonly created_by: number;
}

export interface PageHeaderIF {
    title: string;
    breadCrumbItems: {};
}

export interface Case extends BaseObj {
    name: string,
    description: string,

    [key: string]: any
}

export interface CameraConfiguration {
    camera_id: number;
    sd_resolution: string;
    hd_resolution: string;
    video_frames_per_sec: number;
    picture_every_sec_day: number;
    picture_every_sec_night: number;
    video_record_duration_sec: number;
    wifi_on_cycle_min: number;
    day_starts_at: string;
    night_starts_at: string;
    vid_resolution: string;
    watermark_logo: boolean;
    watermark_date_location: boolean;
    server_sync_every_min: number;
}

export interface Camera extends BaseObj {
    name: string,
    description: string,
    mac_address: string,
    case_id: number | null;
    location_id: number | null;
    location_name: string;
    case_name: string;
    configuration: CameraConfiguration
}

export interface CaseLocation extends BaseObj {
    id: number;
    name: string;
    description: string;
    case_id: number;
    camera_id: number;
    case_name: string;
    regions_of_interest: ROI[];
}

export interface ROI {
    relative_x: number;
    relative_y: number;
    relative_width: number;
    relative_height: number;
}

export interface CaseApiResponse {

}

export interface GetLocationArgs extends GetListResponse {
    caseId: number;
}

export interface GetListResponse {
    count: number;
    limit: number;
    offset: number;
}

export interface GetCasesResponse extends GetListResponse {
    cases: Case[];
}

export interface GetCameraListResponse extends GetListResponse {
    cameras: Camera[];
}

export interface GetLocationsResponse extends GetListResponse {
    locations: CaseLocation[];
}

export interface GetImagesResponse extends GetListResponse {
    images: CameraMedia[];
}

export interface GetROIResponse extends GetListResponse {
    regions: ROI[];
}

export interface AuthUser {
    id: number;
    firstname: string;
    lastname: string;
    token: string;
    is_admin: boolean;
}

export interface User extends BaseObj {
    id: number;
    firstname: string;
    lastname: string;
    email: string;
    is_admin: boolean;

    [key: string]: any;

}

export interface GetUsersResponse extends GetListResponse {
    users: User[];
}

export enum MediaType {
    IMAGE = "IMAGE",
    VIDEO = "VIDEO"
}

export interface CameraMedia extends BaseObj {
    id: number;
    camera_id: number,
    captured_at: string,
    created_at: string,
    created_by: number,
    file_path: string,
    filename: string,
    hd_file_path: string,
    hd_request_status: string,
    location_id: number,
    media_type: string,
    quality: string,
    sequence: string
    [key:string]: any;
}

export interface SelectJob extends BaseObj {
    location_id: number;
    capture_date: string;
    readonly progress: number;
    readonly status: SelectJobStatus;
    threshold: number;
    select_image_ids: number[];
    regions_of_interest: ROI[];
}

export enum SelectJobStatus {
    STARTED = "started",
    IN_PROGRESS = "in_progress",
    FAILED = "failed",
    COMPLETED = "completed",
    CANCELED = "canceled",
    CREATED = "created"
}

export enum HDRequestStatus {
    PENDING = "PENDING",
    COMPLETED = "COMPLETED"
}

export interface HDMediaRequest extends BaseObj {
    status: HDRequestStatus;
    media_id: number;
    filepath: string;
}

export interface CameraStatus extends BaseObj {
    timestamp: number;
    location: {
        latitude: number;
        longitude: number;
    };
    battery: number[];
    temperature: number[];
    lte: number[];
    videos: { count: number; last_timestamp: number; resolution: string };
    images: {
        count: number;
        last_timestamp: number;
        hi_resolution: string;
        lo_resolution: string;
    };
    capacity: {
        remaining: string;
        total: string;
    };
    ram: { remaining: string; total: string };
    cpu_load: string;
    status: string;
    uptime: string;
    version: string; // newly added as Newanwa requested
};


