import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { User } from "../../../helpers/interfaces";

type IProps = {
    isSaving: boolean;
    selectedUser: any;
    show: boolean;
    onHide: () => void;
    onRemoveUser: () => void;
}
const UnassignUserModal = (props: IProps) => {

    return (
        <React.Fragment>
            <Modal
                show={props.show}
                onHide={props.onHide}
                dialogClassName="modal-dialog-centered">
                <Modal.Header
                    onHide={props.onHide} closeButton>
                    <h4 className="modal-title">Remove User ?</h4>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Are you sure you want to remove {" "}
                        <span className="text-danger">
                            {`${props.selectedUser?.firstname} ${props.selectedUser?.lastname} (${props.selectedUser?.email})`}
                        </span>
                        {" "}?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    {props.isSaving && <Spinner className="m-2"/>}
                    <Button
                        disabled={props.isSaving}
                        onClick={() => props.onHide()} variant="secondary">
                        Cancel
                    </Button>
                    <Button
                        disabled={props.isSaving}
                        onClick={props.onRemoveUser}
                        variant="danger">
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>);
}

export default UnassignUserModal