import React, { useEffect, useState } from "react";
import { Camera, CaseLocation } from "helpers/interfaces";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import CameraApi from "api/camera-api";

interface IProps {
    isSaving: boolean;
    action: string;
    location: any;
    show: boolean;
    formError: any;
    onSave: (location: CaseLocation) => void;
    onHide: () => void;
}

const defaultLocation = {
    name: '',
    description: '',
    camera_id: null
}

const cameraApi = new CameraApi();
const LocationFormModal = (props: IProps) => {
    const [nameInputTouched, setNameInputTouched] = useState(false)
    const [location, setLocation] = useState<any>(defaultLocation);
    const [serverError, setServerError] = useState<any>({});
    const modalTitlePrefix = props.action.charAt(0).toUpperCase() + props.action.slice(1);
    const [isSaving, setIsSaving] = useState(props.isSaving);
    const [show, setShow] = useState<any>(props.show);
    const [cameras, setCameras] = useState<Camera[]>()
    const [selectedCamera, setSelectedCamera] = useState<Camera | null>();

    useEffect(() => {
        let location = props.location;
        if (!location) {
            location = defaultLocation
        }
        setLocation(location)
        setShow(props.show);
        resetFormErrors()
    }, [props.location, props.show]);

    useEffect(() => {
        setIsSaving(props.isSaving);
    }, [props.isSaving]);

    useEffect(() => {
        setServerError(props.formError)
    }, [props.formError]);

    useEffect(() => {
        async function getUnassignedCameras() {
            try {
                const { cameras } = await cameraApi.listUnassignedCameras();
                setCameras(cameras)
                setSelectedCamera(null);
            } catch (e) {
                console.error(e);
            }
        }
        getUnassignedCameras();
    }, [props.show]);

    const nameIsValid = Boolean(location.name && location.name.trim() !== '');

    const onFormSubmit = (event: any) => {
        event.preventDefault();
        setNameInputTouched(true);
        if (!nameIsValid) {
            return;
        }
        if (selectedCamera) {
            location.camera_id = selectedCamera?.id
        }
        props.onSave(location)
    }

    const resetFormErrors = () => {
        setNameInputTouched(false);
        setServerError({});
    }

    const onCameraSelected = (event: any) => {
        resetFormErrors();
        const cameraId = Number(event.target.value);
        const cam = cameras?.find(cam => cam.id === cameraId);
        setSelectedCamera(cam)
    }

    const handleInputChange = (event: any) => {
        resetFormErrors();
        setLocation((prevState: CaseLocation) => {
            return {
                ...prevState,
                [event.target.name]: event.target.value
            }
        });
    }

    const onInputTouched = (input: string) => {
        if (input === 'name') {
            setNameInputTouched(true)
        }
    }

    return (
        <React.Fragment>
            <Modal
                show={show}
                onHide={props.onHide}
                dialogClassName="modal-dialog-centered">
                <Modal.Header
                    onHide={props.onHide} closeButton>
                    <h4 className="modal-title">{modalTitlePrefix} Location
                    </h4>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="cameraName">
                        <Form.Label className="text-bold">Name *</Form.Label>
                        <Form.Control
                            type="text"
                            required
                            name="name"
                            isValid={nameInputTouched && nameIsValid}
                            isInvalid={nameInputTouched && !nameIsValid}
                            maxLength={150}
                            onBlur={() => onInputTouched('name')}
                            onChange={handleInputChange}
                            value={location?.name || ""}>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            Please enter valid name
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="camera">
                        <Form.Label className="text-bold">Assign Camera</Form.Label>
                        <Form.Select
                            required
                            onChange={onCameraSelected}
                            value={selectedCamera?.id || ""}
                            name="camera_id"
                            aria-label="Camera">
                            <option disabled value="">Select option</option>
                            {cameras && cameras.map((val, idx) => (
                                <option
                                    key={idx}
                                    value={val.id}>{val.name}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            Please select an option
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="cameraDescription">
                        <Form.Label className="text-bold">Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            name="description"
                            maxLength={300}
                            onChange={handleInputChange}
                            value={location?.description || ""}></Form.Control>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {isSaving && <Spinner className="m-2"/>}
                    {serverError && <p className="text-danger">{serverError?.data?.error}</p>}
                    <Button
                        disabled={isSaving}
                        onClick={onFormSubmit}
                        variant="primary"
                        type="submit">
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}

LocationFormModal.defaultProps = {
    action: "add"
}

export default LocationFormModal;