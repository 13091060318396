import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { Case } from "../../helpers/interfaces";

type CaseFormModalProps = {
    action: string;
    caseInfo: Case | undefined;
    onSave: (caseInfo: Case) => void;
    onHide: () => void;
    isSaving: boolean;
    show: boolean;
    serverError: any;
}

const defaultCase: Case = {
    id: 0,
    name: '',
    description: '',
    created_at: '',
    created_by: 0
}

const CaseFormModal = ({...props}: CaseFormModalProps) => {
    const modalTitlePrefix = props.action.charAt(0).toUpperCase() + props.action.slice(1);
    const [caseInfo, setCaseInfo] = useState(defaultCase);
    const [nameInputTouched, setNameInputTouched] = useState(false)
    const [isSaving, setIsSaving] = useState(props.isSaving);
    const [serverError, setServerError] = useState<any>({});
    const [show, setShow] = useState<any>(props.show);

    useEffect(() => {
        let caseInfo = props.caseInfo;
        if (!caseInfo) {
            caseInfo = defaultCase;
        }
        setCaseInfo(caseInfo)
    }, [props.caseInfo]);

    useEffect(() => {
        setIsSaving(props.isSaving);
    }, [props.isSaving]);

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    const nameIsValid = Boolean(caseInfo.name && caseInfo.name.trim() !== '');

    const handleInputChange = (event: any) => {
        resetFormErrors();
        setCaseInfo((prevState: Case) => {
            return {
                ...prevState,
                [event.target.name]: event.target.value
            }
        });
    }

    const resetFormErrors = () => {
        setNameInputTouched(false);
        setServerError({});
    }

    const onInputTouched = (input: string) => {
        if (input === 'name') {
            setNameInputTouched(true)
        }
    }

    const onFormSubmit = (event: any) => {
        event.preventDefault();
        setNameInputTouched(true);
        if (!nameIsValid) {
            return;
        }
        props.onSave(caseInfo)
    }

    return (
        <Modal
            show={show}
            onHide={props.onHide}
            dialogClassName="modal-dialog-centered">
            <Modal.Header
                onHide={props.onHide} closeButton>
                <h4 className="modal-title">{modalTitlePrefix} Case
                </h4>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3" controlId="cameraName">
                    <Form.Label className="text-bold">Name *</Form.Label>
                    <Form.Control
                        type="text"
                        required
                        name="name"
                        isValid={nameInputTouched && nameIsValid}
                        isInvalid={nameInputTouched && !nameIsValid}
                        maxLength={150}
                        onBlur={() => onInputTouched('name')}
                        onChange={handleInputChange}
                        value={caseInfo?.name || ""}>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        Please enter valid name
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="cameraDescription">
                    <Form.Label className="text-bold">Description</Form.Label>
                    <Form.Control
                        as="textarea"
                        name="description"
                        maxLength={300}
                        onChange={handleInputChange}
                        value={caseInfo?.description || ""}></Form.Control>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                {isSaving && <Spinner className="m-2"/>}
                <Button
                    disabled={isSaving}
                    onClick={onFormSubmit}
                    variant="primary"
                    type="submit">
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

CaseFormModal.defaultProps = {
    action: "add"
}

export default CaseFormModal;