import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";

type IProps = {
    isSaving: boolean;
    selectedLocation: any;
    show: boolean;
    onHide: () => void;
    onDeleteLocation: () => void;
}
const DeleteLocation = (props: IProps) => {

    return (
        <React.Fragment>
            <Modal
                show={props.show}
                onHide={props.onHide}
                dialogClassName="modal-dialog-centered">
                <Modal.Header
                    onHide={props.onHide} closeButton>
                    <h4 className="modal-title">Delete Location ?</h4>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Are you sure you want to delete {" "}
                        <span className="text-danger">
                            {props.selectedLocation?.name}
                        </span>
                        {" "}?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    {props.isSaving && <Spinner className="m-2"/>}
                    <Button
                        disabled={props.isSaving}
                        onClick={() => props.onHide()} variant="secondary">
                        Cancel
                    </Button>
                    <Button
                        disabled={props.isSaving}
                        onClick={props.onDeleteLocation}
                        variant="danger">
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>);
}

export default DeleteLocation