import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { CameraConfiguration } from "../../helpers/interfaces";
import classNames from "classnames";

const sd_resolution = [
    "480x320",
    "720x480"
];

const hd_resolution = [
    "1920x1080",
    "2560x1440",
    "3840x2160"
];

const picture_every_sec_day = [
    0,5, 7, 10, 15, 30, 60
];

const picture_every_sec_night = [
    0, 60, 120, 180 , 240, 300, 360
];

const vid_resolution=[
    "480x320",
    "720x480",
    "1920x1080"
]

const video_frames_per_msec = [
    10, 20, 30, 40, 50, 60
]

const video_record_duration_sec = [
    60, 180, 300, 420, 600
]

const server_sync_every_min =[
    15, 30, 60, 360, 720, 1440]

const watermark_logo = [
    true,
    false]

const watermark_date_location=[
    true,
    false]


const wifi_on_cycle_min = [
    0, 15, 30,60,75,90,120
]
const convertSecToMin = (duration: number) => {
    let mins=Math.floor(duration/60);
    var secs = duration%60;
    return mins + " mins " +(secs > 0 ? secs + " sec" : "")

}

const convertMinToHr = (duration: number) => {
    if (duration<60){
        return duration +" min"
    }
    let hours = (duration / 60);
    var whole_hours = Math.floor(hours);
    var minutes = (hours - whole_hours) * 60;
    var whole_minutes = Math.round(minutes);
    return whole_hours + " hr " + (whole_minutes > 0 ? whole_minutes + " min" : "");
}

const CameraConfigurationModal = (props: any) => {
    const [configuration, setConfiguration] = useState<CameraConfiguration>(props.camera?.configuration)
    const [isValidated, setIsValidated] = useState(false)
    const [serverError, setServerError] = useState<any>({});

    useEffect(() => {
        setServerError(props.serverError)
    }, [props.serverError]);

    const handleInputChange = (event: any) => {
        resetFormErrors();
        let value = event.target.value;

        if (value === "true") {
            value = true;
        } else if (value === "false") {
            value = false;
        }

        setConfiguration((prevState: CameraConfiguration) => {
            return {
                ...prevState,
                [event.target.name]: value
            }
        });
    }

    const resetFormErrors = () => {
        setServerError({});
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        setIsValidated(true);

        const form = event.currentTarget;
        if (form.checkValidity() === true) {
            props.onSave(props.camera, configuration);
        }
    }

    const hours = Array.from({ length: 48 }, (_, i) => {
        const hour = Math.floor(i / 2).toString().padStart(2, '0');
        const minute = (i % 2) === 0 ? '00' : '30';
        return `${hour}:${minute}:00`;
        });
    const starColor ={color:'red'};


    return (
        <Modal show={true}
            onHide={props.onHide}
            size="xl"
            dialogClassName="modal-dialog-centered">
            <Form className={classNames({"was-validated": isValidated})} noValidate onSubmit={handleSubmit}>
                <Modal.Header
                    onHide={props.onHide} closeButton>
                    <h4 className="modal-title">
                        Configure Camera: {" "}
                        <span
                            className="text-primary">
                        {props.camera?.name}
                    </span>
                    </h4>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-3">
                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="lowRes" >
                                <Form.Label className="text-bold"  >SD Resolution <span style={starColor}>*</span> </Form.Label>
                                <Form.Select
                                    required
                                    onChange={handleInputChange}
                                    value={configuration.sd_resolution || ""}
                                    name="sd_resolution"
                                    aria-label="SD resolution">
                                    <option disabled value="">Select option</option>
                                    {sd_resolution.map((val, idx) => (
                                        <option
                                            key={idx}
                                            value={val}>{val}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid" style={{ clear: 'both' }}>
                                    Please select an option
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="highRes" >
                                <Form.Label className="text-bold" >HD Resolution <span style={starColor}>*</span> </Form.Label>
                                <Form.Select
                                    required
                                    onChange={handleInputChange}
                                    value={configuration.hd_resolution || ""}
                                    name="hd_resolution"
                                    aria-label="HD resolution">
                                    <option disabled value="">Select option</option>
                                    {hd_resolution.map((val, idx) => (
                                        <option key={idx} value={val}>{val}</option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Please select an option
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="pictureFrequency" >
                                <Form.Label className="text-bold" >Day Time Picture Every <span style={starColor}>*</span> </Form.Label>
                                <Form.Select
                                    required
                                    onChange={handleInputChange}
                                    value={configuration.picture_every_sec_day || ""}
                                    name="picture_every_sec_day"
                                    aria-label="Picture every day">

                                    <option disabled value="">Select option</option>
                                    {picture_every_sec_day.map((val, idx) => (
                                        <option key={idx} value={val}>{val===0?"OFF":val + ' sec'}</option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Please select an option
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="pictureFrequency" >
                                <Form.Label className="text-bold" >Night Time Picture Every <span style={starColor}>*</span> </Form.Label>
                                <Form.Select
                                    required
                                    onChange={handleInputChange}
                                    value={configuration.picture_every_sec_night || ""}
                                    name="picture_every_sec_night"
                                    aria-label="Picture every night">

                                    <option disabled value="">Select option</option>
                                    {picture_every_sec_night.map((val, idx) => (
                                        <option key={idx} value={val}>{val==0?"OFF":convertSecToMin(val)}</option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Please select an option
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="videoResolution" >
                                <Form.Label className="text-bold" >Video Resolution <span style={starColor}>*</span> </Form.Label>
                                <Form.Select
                                    required
                                    onChange={handleInputChange}
                                    value={configuration.vid_resolution || ""}
                                    name="vid_resolution"
                                    aria-label="Vid resolution">
                                    <option disabled value="">video_resolution</option>
                                    {vid_resolution.map((val, idx) => (
                                        <option key={idx} value={val}>{val}</option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Please select an option
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="frameRate" >
                                <Form.Label className="text-bold" >Video Frames Per Sec <span style={starColor}>*</span> </Form.Label>
                                <Form.Select
                                    required
                                    onChange={handleInputChange}
                                    value={configuration.video_frames_per_sec || ""}
                                    name="video_frames_per_sec"
                                    aria-label="Frame rate"
                                    >
                                    <option disabled value="">video_frames_per_msec</option>
                                    {video_frames_per_msec.map((val, idx) => (
                                        <option key={idx} value={val}>{val}fps</option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Please select an option
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="wifiOn" >
                                <Form.Label className="text-bold" >Turn WiFi On Every <span style={starColor}>*</span> </Form.Label>
                                <Form.Select
                                    required
                                    onChange={handleInputChange}
                                    value={configuration.wifi_on_cycle_min || ""}
                                    name="wifi_on_cycle_min"
                                    aria-label="wifi on cycle min"
                                    >
                                    <option disabled value="">Select option</option>
                                    {wifi_on_cycle_min.map((val, idx) => (
                                        <option key={idx} value={val}>{convertMinToHr(val)}</option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Please select an option
                                </Form.Control.Feedback>
                            </Form.Group>
                            </Col>
                            <Col md={6}>
                            <Form.Group className="mb-3" controlId="videoDuration" >
                                <Form.Label className="text-bold" >Video Record Duration <span style={starColor}>*</span> </Form.Label>
                                <Form.Select
                                    required
                                    onChange={handleInputChange}
                                    value={configuration.video_record_duration_sec || ""}
                                    name="record_duration_sec"
                                    aria-label="Video duration"
                                    >
                                    <option disabled value="">video_record_duration_sec</option>
                                    {video_record_duration_sec.map((val, idx) => (
                                        <option key={idx} value={val}>{(val / 60)} minute{(val / 60) > 1 && "s"}</option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Please select an option
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="serverSync" >
                                <Form.Label className="text-bold" >Server Sync Every <span style={starColor}>*</span> </Form.Label>
                                <Form.Select
                                    required
                                    onChange={handleInputChange}
                                    value={configuration.server_sync_every_min || ""}
                                    name="server_sync_every_min"
                                    aria-label="camera to server synchnization"
                                    >
                                    <option disabled value="">Select option</option>
                                    {server_sync_every_min.map((val, idx) => (
                                        <option key={idx} value={val}>{convertMinToHr(val)}</option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Please select an option
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="wakeAt">
                                <Form.Label className="text-bold">Day Starts At <span style={starColor}>*</span> </Form.Label>
                                <Form.Select
                                    required
                                    onChange={handleInputChange}
                                    value={configuration.day_starts_at || ""}
                                    name="day_starts_at"
                                    aria-label="Day starts at"
                                >
                                    <option disabled value="">
                                    Select option
                                    </option>
                                    {hours.map((hour, idx) => (
                                    <option key={idx} value={hour}>
                                        {hour}
                                    </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">Please select an option</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="nightStartsAt">
                                <Form.Label className="text-bold">Night Starts At <span style={starColor}>*</span> </Form.Label>
                                <Form.Select
                                    required
                                    onChange={handleInputChange}
                                    value={configuration.night_starts_at || ""}
                                    name="night_starts_at"
                                    aria-label="night starts at"
                                >
                                    <option disabled value="">
                                    Select option
                                    </option>
                                    {hours.map((hour, idx) => (
                                    <option key={idx} value={hour}>
                                        {hour}
                                    </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">Please select an option</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="watermarkLogo" >
                                <Form.Label className="text-bold" >Watermark Logo <span style={starColor}>*</span> </Form.Label>
                                <Form.Select
                                    required
                                    onChange={handleInputChange}
                                    value={configuration.watermark_logo ? "true" : "false"}
                                    name="watermark_logo"
                                    aria-label="Watermark Logo">
                                    <option disabled value="">Select option</option>
                                    {watermark_logo.map((val, idx) => (
                                        <option key={idx} value={val.toString()}>{val ? 'Enabled' : 'Disabled'}</option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Please select an option
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="watermarkDateLocation" >
                                <Form.Label className="text-bold" >Watermark Date Location <span style={starColor}>*</span> </Form.Label>
                                <Form.Select
                                    required
                                    onChange={handleInputChange}
                                    value={configuration.watermark_date_location ? "true" : "false"}
                                    name="watermark_date_location"
                                    aria-label="Watermark Date Location"
                                    >
                                    <option disabled value="">Select option</option>
                                    {watermark_date_location.map((val, idx) => (
                                        <option key={idx} value={val.toString()}>{val ? 'Enabled' : 'Disabled'}</option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Please select an option
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    {serverError && <p className="text-danger">{serverError?.data?.error}</p>}
                    {props.isSaving && <Spinner className="m-2"/>}
                    <Button
                        disabled={props.isSaving}
                        onClick={() => props.onHide()} variant="secondary">
                        Cancel
                    </Button>
                    <Button
                        disabled={props.isSaving}
                        type="submit"
                        variant="primary">
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default CameraConfigurationModal;