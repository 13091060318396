import React, { useEffect, useState } from 'react';
import PageTitle from '../../../components/PageTitle';
import { Card, Spinner } from 'react-bootstrap';
import FolderItem from '../../../components/FolderItem';
import { useNavigate, useParams } from "react-router-dom";
import CaseApi from "../../../api/case-api";
import { CaseLocation } from "../../../helpers/interfaces";
import { getDisplayDate } from "../../../helpers/utils";


const caseApi = new CaseApi();

const LocationDates = (props: any) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [captureDates, setCaptureDates] = useState<string[]>([])
    const [selectedCase, setSelectedCase] = useState<any>({})
    const [selectedLocation, setSelectedLocation] = useState<CaseLocation>()
    const params = useParams();
    const caseId = Number(params.caseId)
    const locationId = Number(params.locationId)

    async function fetchData() {
        setIsLoading(true);
        try {
            const _case = await caseApi.fetchCase(caseId)
            setSelectedCase(_case);

        } catch (e) {
            console.log("Error fetching data", e);
        } finally {
            setIsLoading(false);
        }
    }

    async function fetchCaptureDates() {
        try {
            setIsLoading(true);
            const {dates} = await caseApi.listLocationCaptureDates(caseId, locationId)
            setCaptureDates([...dates]);
        } catch (e) {
            console.error(e)
        } finally {
            setIsLoading(false)
        }
    }

    async function fetchLocationInfo() {
        try {
            setIsLoading(true);
            const location = await caseApi.getLocation(caseId, locationId)
            setSelectedLocation(location);
        } catch (e) {
            console.error(e)
        } finally {
            setIsLoading(false)
        }
    }

    // Fetch data
    useEffect(() => {
        fetchData();
        fetchCaptureDates()
        fetchLocationInfo()
    }, []);


    const onDateClicked = (captureDate: string) => {
        navigate(`/cases/${caseId}/locations/${locationId}/${captureDate}`)
    }

    return (
        <React.Fragment>
            <PageTitle
                breadCrumbItems={[
                    {label: "Cases", path: "/cases"},
                    {label: "View Cases", path: "/cases"},
                    {label: `${selectedCase?.name}`, path: `/cases/${selectedCase?.id}`},
                    {label: "Locations", path: `/cases/${selectedCase?.id}/locations`},
                    {label: `${selectedLocation?.name}`, active: true}
                ]}
                title={`${selectedLocation?.name}`}
            />
            <Card>
                <Card.Body>
                    {isLoading &&
                        <div className="d-flex justify-content-center">
                            <Spinner className="text-primary m-2"/>
                        </div>
                    }
                    {captureDates.length === 0 && <span className="text-muted">No records found</span>}
                    <div className="folder-list-container">
                        {captureDates.map((dt, idx) => {
                            return (
                                <React.Fragment key={idx}>
                                    <FolderItem
                                        onClick={() => onDateClicked(dt)}
                                        title={getDisplayDate(dt)}></FolderItem>
                                </React.Fragment>
                            )
                        })}
                    </div>
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}

export default LocationDates
