import React, { useContext, useEffect, useState } from 'react';
import PageTitle from 'components/PageTitle';
import { Button, Card, Col, OverlayTrigger, Row, Spinner, Tooltip,Modal } from "react-bootstrap";
import Table from 'components/Table'
import { Link } from "react-router-dom";
import CameraApi from "api/camera-api";
import { Camera, CameraConfiguration } from "helpers/interfaces";
import CameraFormModal from "./CameraFormModal";
import DeleteCameraModal from "./DeleteCameraModal";
import CameraConfigurationModal from "./CameraConfigurationModal";
import CameraAssignModal from "./CameraAssignModal";
import CameraStatuses from './CameraStatus';
import { AppContext } from "components/layout/RootLayout"
import { CustomLink } from "helpers/utils";


const cameraApi = new CameraApi();

const CamerasPage = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [cameras, setCameras] = useState<Camera[]>([]);
    const [cameraToDelete, setCameraToDelete] = useState<Camera>();
    const [selectedCamera, setSelectedCamera] = useState<Camera>();
    const [showDeleteCameraModal, setShowDeleteCameraModal] = useState<boolean>(false);
    const [showCameraFormModal, setShowCameraFormModal] = useState<boolean>(false);
    const [modalAction, setModalAction] = useState<string>()
    const [modalCameraInfo, setModalCameraInfo] = useState<Camera | {}>();
    const [serverFormError, setServerFormError] = useState({});
    const [showCameraConfigModal, setShowCameraConfigModal] = useState(false);
    const [showCameraAssignModal, setShowCameraAssignModal] = useState(false);
    const {
        showToast
    } = useContext(AppContext)
    const [showCameraStatusModal, setShowCameraStatusModal] = useState(false);

    const columns = [
        {
            header: "Name",
            accessorKey: "name",
        },
        {
            header: "MAC Address",
            accessorKey: "mac_address",
        },
        {
            header: "Case",
            accessorKey: "case_name",
            cell: (prop: any) => CaseColumn(prop),
        },
        {
            header: "Location",
            accessorKey: "location_name",
        },
        {
            header: "Description",
            accessorKey: "description",
        },
        {
            header: "Date Added",
            accessorKey: "created_at",
            cell: (prop: any) => new Date(prop.getValue()).toISOString().split('T')[0],
            sort: true
        },
        {
            header: "Actions",
            accessorKey: "actions",
            cell: (prop: any) => ActionColumn(prop)
        }
    ]

    const CaseColumn = (prop: any) => {
        return (
            <Link to={`/cases/manage/${prop.row.original.case_id}`}>{prop.getValue()}</Link>
        );
    }

    const ActionColumn = (prop: any) => {
        const camera = prop.row.original;
        return (
            <>
                <CustomLink title="Edit Camera" to="#" onClick={() => toggleCameraModal("edit", camera)}>
                    {" "}
                    <span className="material-symbols-outlined action-icon">edit</span>
                </CustomLink>
                <CustomLink title="Delete Camera" to="#" onClick={() => toggleDeleteCameraModal(camera)}>
                    {" "}
                    <span className="material-symbols-outlined action-icon action-delete">delete</span>
                </CustomLink>
                <CustomLink title="Configure Camera" to="#" onClick={() => toggleCameraConfigModal(camera)}>
                    {" "}
                    <span className="material-symbols-outlined action-icon">settings</span>
                </CustomLink>
                <CustomLink title="Assign Camera" to="#" onClick={() => toggleCameraAssignModal(camera)}>
                    {" "}
                    <span className="material-symbols-outlined action-icon">cameraswitch</span>
                </CustomLink>
                <CustomLink title="View Camera Status" to="#" onClick={() => toggleCameraStatusModal(camera)}>
                <span className="material-symbols-outlined action-icon">visibility</span>
            </CustomLink>
            </>
        );
    };

    // Fetch list of cameras
    useEffect(() => {
        if (showCameraAssignModal) {
            return;
        }

        async function fetchCameras() {
            setIsLoading(true);
            try {
                const resp = await cameraApi.listCameras({
                    offset: 0,
                    limit: 1000,
                    assigned: false
                })
                console.log("Camera-data",resp)
                setCameras(resp.cameras);
            } catch (e) {
                console.log("Error loading cameras", e)
            } finally {
                setIsLoading(false)
            }
        }

        fetchCameras();
    }, [showCameraAssignModal])

    const onAddCamera = async (camera: Camera) => {
        try {
            setIsSaving(true);
            const resp = await cameraApi.addCamera(camera);
            setCameras([resp, ...cameras]);
            toggleCameraModal();
        } catch (e: any) {
            setServerFormError(e)
        } finally {
            setIsSaving(false);
        }
    }

    const onUpdateCamera = async (newData: Camera) => {
        try {
            setIsSaving(true);
            const updatedCamera = await cameraApi.updateCamera(newData);
            // update table
            const idx = cameras.findIndex(camera => camera.id === updatedCamera.id);
            cameras[idx] = updatedCamera
            setCameras([...cameras])
            toggleCameraModal();
        } catch (e: any) {
            setServerFormError(e)
        } finally {
            setIsSaving(false);
        }
    }

    const onDeleteCamera = async () => {
        if (cameraToDelete === undefined) {
            console.log('Error deleting camera')
            return
        }
        try {
            setIsSaving(true);
            await cameraApi.deleteCamera(cameraToDelete.id);
            // Update table
            const idx = cameras.findIndex(cam => cam.id === cameraToDelete.id);
            cameras.splice(idx, 1);
            setCameras([...cameras]);
        } catch (e) {
            console.error("Error saving camera", e)
        } finally {
            setIsSaving(false);
            toggleDeleteCameraModal();
        }
    }

    const toggleCameraModal = (action?: string, cameraToEdit?: Camera | {}) => {
        setShowCameraFormModal(!showCameraFormModal);
        setModalAction(action);
        setModalCameraInfo(cameraToEdit);
        setServerFormError({});
    }

    const toggleCameraConfigModal = (camera = undefined) => {
        setShowCameraConfigModal(!showCameraConfigModal);
        setSelectedCamera(camera);
    }

    const toggleCameraAssignModal = (camera: Camera) => {
        setShowCameraAssignModal(!showCameraAssignModal);
        setSelectedCamera(camera);
    }

    const toggleDeleteCameraModal = (camera = undefined) => {
        setShowDeleteCameraModal(!showDeleteCameraModal);
        setCameraToDelete(camera);
    }

    const toggleCameraStatusModal = (camera?: Camera) => {
        if (camera) {
            setSelectedCamera(camera);
        } else {
            setSelectedCamera(undefined);        }
        setShowCameraStatusModal(!showCameraStatusModal);
    };

    const onSaveCamera = async (camera: Camera) => {
        if (modalAction === "edit") {
            return onUpdateCamera(camera)
        }
        if (modalAction === "add") {
            return onAddCamera(camera);
        }
    }

    const onSaveCameraConfiguration = async (selectedCamera: Camera, config: CameraConfiguration) => {
        try {
            setIsSaving(true);
            let method = "PUT";
            if (Object.keys(selectedCamera.configuration).length === 0) {
                method = "POST";
            }
            const updatedConfig = await cameraApi.updateCameraConfig(selectedCamera.id, config, method);
            // update table
            const idx = cameras.findIndex(camera => selectedCamera.id === camera.id);
            cameras[idx].configuration = updatedConfig
            setCameras([...cameras])
            toggleCameraConfigModal();
        } catch (e: any) {
            console.log(e)
            setServerFormError(e)
        } finally {
            setIsSaving(false);
        }
    }

    const AddButton = () => {
        return (
            <React.Fragment>
                <Button
                    variant="primary"
                    className="btn-add"
                    onClick={() => toggleCameraModal("add", {})}>
                    Add Camera
                </Button>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <PageTitle
                breadCrumbItems={[
                    {label: "Cameras", path: "/cameras", active: true},
                ]}
                title="Cameras"
                actionBtnText="Add Camera"
                onActionBtnClicked={() => toggleCameraModal("add", {})}
            />
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Table
                                classNames="table-striped"
                                isSearchable={true}
                                actionButton={AddButton}
                                columns={columns}
                                data={cameras}
                            />
                            {!isLoading && cameras.length == 0 &&
                                <span className="text-muted">No records found</span>
                            }
                            {isLoading &&
                                <div className="d-flex justify-content-center">
                                    <div>
                                        <Spinner className="text-primary m-2"/>
                                    </div>
                                </div>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {showCameraFormModal &&
                <CameraFormModal
                    action={modalAction}
                    cameraInfo={modalCameraInfo}
                    isSaving={isSaving}
                    onHide={toggleCameraModal}
                    onSave={onSaveCamera}
                    serverError={serverFormError}
                ></CameraFormModal>
            }
            {showDeleteCameraModal &&
                <DeleteCameraModal
                    isSaving={isSaving}
                    cameraToDelete={cameraToDelete}
                    onHide={toggleDeleteCameraModal}
                    onDelete={onDeleteCamera}
                />
            }
            {showCameraConfigModal &&
                <CameraConfigurationModal
                    serverError={serverFormError}
                    isSaving={isSaving}
                    camera={selectedCamera}
                    onSave={onSaveCameraConfiguration}
                    onHide={toggleCameraConfigModal}
                />
            }

            {showCameraAssignModal &&
                <CameraAssignModal
                    camera={selectedCamera}
                    onSuccess={(msg: string) => {
                        showToast(msg, "info");
                    }}
                    onHide={toggleCameraAssignModal}/>
            }

            {showCameraStatusModal && selectedCamera && (
                <Modal show={showCameraStatusModal} onHide={() => toggleCameraStatusModal()} dialogClassName="custom-modal-dialog">
                    <div className="custom-modal-content">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <span style={{ color: '#00aeef' }}>
                                    {`${selectedCamera.case_name || "No-Case"} > ${selectedCamera.location_name || "No-Location"} > Camera Status`}
                                </span>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="custom-modal-body">
                            <CameraStatuses cameraId={selectedCamera.id} />
                        </Modal.Body>
                    </div>
                </Modal>
            )}

        </React.Fragment>
    )
}

export default CamerasPage
