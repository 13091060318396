import React from 'react';
import { Row, Col,ProgressBar } from 'react-bootstrap';

interface CameraStatusElementsProps {
    label: string;
    value: string | number | JSX.Element;
}
interface CustomProgressprops {
    elementName: string;
    currentValue: number;
    totalValue: number;
    elementUnit: string;

}

const CameraStatusElements: React.FC<CameraStatusElementsProps> = ({ label, value }) => {
    return (
        <li className="camera-status-element">
        <Row className="g-0 align-items-center">
            <Col xs={4} className="camera-status-label">
            {label}
            </Col>
            <Col xs={8} className="text-right camera-status-value">
            {value}
            </Col>
        </Row>
        </li>
    );
    };

const CustomProgressBar: React.FC<CustomProgressprops> = ({ elementName,currentValue, totalValue, elementUnit }) => {
    let currentPercentage = ((1-(currentValue/totalValue))*100);
    const getColorVariant = () => {
        if (currentPercentage >=75 )
        {
            return 'danger';
        }
        else if (currentPercentage >= 50)
        {
            return "warning";
        }
        else {
            return 'success';
        }
    }
    return (
        <li className="camera-status-element">
            <Row className="g-0 align-items-center">
                <Col xs={4} className="camera-status-label">
                {elementName}
                </Col>
                <Col xs={8} className="text-right camera-status-value">
                <div className="position-relative" style={{ height: '20px' }}>
                    <div className="text-muted small position-absolute" style={{ right: '50%', top: '-20px', transform: 'translateX(50%)' }}>
                    {currentPercentage.toFixed(2)}%
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ProgressBar
                        min={0}
                        max={100}
                        animated
                        now={currentPercentage}
                        variant={getColorVariant()}
                        style={{ width: '70%', height: '15px', marginRight: '5px' }}
                    />
                    <span className="text-muted small">{totalValue.toFixed(2)} {elementUnit}</span>
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <span className="text-muted small">0 {elementUnit}</span>
                </div>
                </Col>
            </Row>
        </li>

    );
    };

export { CameraStatusElements, CustomProgressBar};
