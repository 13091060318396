import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";

const DeleteUserModal = (props: any) => {

    return (
        <Modal show={props.show}
               onHide={props.onHide}
               dialogClassName="modal-dialog-centered">
            <Modal.Header
                onHide={props.onHide} closeButton>
                <h4 className="modal-title">
                    Delete {" "}
                    <span
                        className="text-danger">
                        {props.userToDelete.firstname} {props.userToDelete.lastname}
                    </span> ?
                </h4>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete this user?</p>
            </Modal.Body>
            <Modal.Footer>
                {props.isSaving && <Spinner className="m-2"/>}
                <Button
                    disabled={props.isSaving}
                    onClick={() => props.onHide()} variant="secondary">
                    Cancel
                </Button>
                <Button
                    disabled={props.isSaving}
                    onClick={props.onDelete} variant="danger">
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DeleteUserModal;