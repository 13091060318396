import React from 'react';
import { Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);


type GraphProps = {
    xAxisData: string[];
    yAxisData: number[];
    title: string;
    yAxisLabel: string;
    xAxisLabel: string;
};

const CustomLineGraph: React.FC<GraphProps> = ({ xAxisData, yAxisData, title, yAxisLabel, xAxisLabel }) => {
    const getRandomRgb = () => {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        return `rgb(${r}, ${g}, ${b})`;
    };

    const data = {
        labels: xAxisData,
        datasets: [
            {
                label: yAxisLabel,
                data: yAxisData,
                fill: false,
                borderColor: getRandomRgb(),
                tension: 0.1,
            },
        ],
    };

    const options = {
        plugins: {
            title: {
                display: true,
                text: title,
            },
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
                title: {
                    display: true,
                    text: yAxisLabel,
                },
            },
            x: {
                title: {
                    display: false,
                    text: xAxisLabel,
                },
                ticks: {
                    maxRotation: 45,
                    minRotation: 45,}
            },
        },
    };

    return <Line data={data} options={options} />;
};

const CustomBarGraph: React.FC<GraphProps> = ({ xAxisData, yAxisData, title, yAxisLabel, xAxisLabel }) => {
    const data = {
    labels: xAxisData,
    datasets: [{
        label: yAxisLabel,
        data: yAxisData,
        backgroundColor: 'rgba(0, 0, 255, 0.2)',
        borderColor: 'rgba(100, 100, 100, 1)',
        borderWidth: 1
    }]
    };

    const options = {
    plugins: {
        title: {
        display: true,
        text: title
        },
        legend: {
        display:false
        }
    },
    scales: {
        y: {
        title: {
            display: true,
            text: yAxisLabel
        }
        },
        x: {
        title: {
            display: false,
            text: xAxisLabel
        },
        ticks: {
            maxRotation: 45,
            minRotation: 45,}
        }
    }
    };

    return <Bar data={data} options={options} />;
    };


export {CustomLineGraph,CustomBarGraph};
