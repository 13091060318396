import { redirect } from "react-router-dom";
import { AuthUser } from "./interfaces";
import UserApi from "../api/user-api";

const userApi = new UserApi();

export const getAuthUser = (): AuthUser | null => {
    const data = localStorage.getItem("authUser") || "{}";
    let authUser;
    try {
        authUser = JSON.parse(data) as AuthUser;
    } catch (e) {
        console.error('Error reading authUser', e)
        authUser = null;
    }
    return authUser;
};

export const AuthLoader = () => {
    const authUser = getAuthUser();
    if (authUser === null) {
        return redirect('/logout');
    }

    if (!authUser["token"]) {
        return redirect('/login');
    }
    return authUser;
};

export const AdminRoute = async () => {
    const authUser = getAuthUser();
    if (authUser === null) {
        return redirect('/logout');
    }

    try {
        const user = await userApi.getUser(authUser.id);
        if (!user.is_admin) {
            return redirect('/');
        }
        return true
    } catch (e) {
        console.log('Could not verify user permissions')
        return false
    }
};