import { BackendApi } from "./backend-api";
import { GetImagesResponse, SelectJob } from "../helpers/interfaces";

export default class SelectApi {
    private backendApi: BackendApi;

    constructor() {
        this.backendApi = BackendApi.Instance;
    }

    async getLatestSelectJob(locationId: number, captureDate: string): Promise<SelectJob> {
        return this.backendApi.getApiResponse({
            endPoint: `/select/latest`,
            queryStringParams: {
                locationId: locationId,
                captureDate: captureDate
            },

        })

    }

    async createSelectJob(data: {}): Promise<SelectJob> {
        return this.backendApi.getApiResponse({
            endPoint: '/select',
            method: 'POST',
            data: data

        })
    }

    async cancelSelectJob(jobId: number | undefined) {
        return this.backendApi.getApiResponse({
            endPoint: `/select/${jobId}/cancel`,
            method: 'PUT'
        })
    }

    async fetchSelectImages(jobId: number, args: { offset: number; limit: any }): Promise<GetImagesResponse> {
        return this.backendApi.getApiResponse({
            endPoint: `/select/${jobId}/images`,
            queryStringParams: args
        })
    }
}
