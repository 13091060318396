import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { CaseLocation } from "helpers/interfaces";
import Table from "components/Table";
import { Button, Spinner } from "react-bootstrap";
import CaseApi from "api/case-api";
import LocationFormModal from "./LocationFormModal";
import DeleteLocation from "./DeleteLocation";
import { AppContext } from "components/layout/RootLayout"

type IProps = {
    caseId: number;
}
const caseApi = new CaseApi();

const LocationsTable = ({caseId, ...props}: IProps) => {
    const [caseLocations, setCaseLocations] = useState<CaseLocation[]>([]);
    const [isLoading, setIsLoading] = useState(false)
    const [isSaving, setIsSaving] = useState(false);
    const [showAddLocationModal, setShowAddLocationModal] = useState(false);
    const [showDeleteLocationModal, setShowDeleteLocationModal] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState<CaseLocation>();
    const [modalAction, setModalAction] = useState<string>();
    const [formError, setFormError] = useState({});
    const {
        showToast
    } = useContext(AppContext)

    useEffect(() => {
        async function fetchLocations() {
            setIsLoading(true);
            setCaseLocations([]);
            try {
                const {locations} = await caseApi.listLocations({caseId});
                setCaseLocations([...locations]);
            } catch (e) {
                console.error('Error loading users');
            } finally {
                setIsLoading(false);
            }
        }

        fetchLocations();
    }, []);

    const columns = useMemo(() => [
        {
            header: "Name",
            accessorKey: "name",
        },
        {
            header: "Description",
            accessorKey: "description",
        },
        {
            header: 'Assigned Camera',
            accessorKey: 'camera_name'
        },
        {
            header: "Date Added",
            accessorKey: "created_at",
            sort: true,
            cell: (prop: any) => prop.getValue().split('T')[0],
        },
        {
            header: "Actions",
            accessorKey: "actions",
            cell: (prop: any) => ActionColumn(prop)
        }
    ], []);

    const ActionColumn = (prop: any) => {
        const location = prop.row.original;
        return (
            <>
                <Link to="#" onClick={() => toggleLocationFormModal("edit", location)
                }>
                    {" "}
                    <span className="material-symbols-outlined action-icon">edit</span>
                </Link>
                <Link to="#" onClick={() => toggleDeleteLocationModal(location)
                }>
                    {" "}
                    <span className="material-symbols-outlined action-icon action-delete">delete</span>
                </Link>
            </>
        );
    };

    const toggleLocationFormModal = (action?: string, location?: CaseLocation) => {
        setShowAddLocationModal(!showAddLocationModal);
        setSelectedLocation(location);
        setModalAction(action);
        setFormError({});
    }

    const toggleDeleteLocationModal = (location?: CaseLocation) => {
        setSelectedLocation(location)
        setShowDeleteLocationModal(!showDeleteLocationModal);
    }

    const updateLocation = async (locationUpdate: any) => {
        try {
            setIsSaving(true);
            const updatedLocation = await caseApi.updateLocation(caseId, locationUpdate["id"], locationUpdate);
            // update table
            const idx = caseLocations.findIndex(location => location.id === updatedLocation.id);
            caseLocations[idx] = updatedLocation
            setCaseLocations([...caseLocations])
        } catch (e: any) {
            setFormError(e)
            showToast(e.data.error, "warning")
        } finally {
            toggleLocationFormModal();
            setIsSaving(false);
        }
    }

    const addLocation = async (location: CaseLocation) => {
        try {
            setIsSaving(true);
            const resp = await caseApi.addLocation(caseId, location);
            caseLocations.push(resp);
            setCaseLocations([...caseLocations]);
            toggleLocationFormModal();
        } catch (e: any) {
            console.error('Error adding location');
            setFormError(e)
        } finally {
            setIsSaving(false);
        }
    }

    const onSaveLocation = async (location: CaseLocation) => {
        location["case_id"] = caseId;
        if (modalAction === "edit") {
            return updateLocation(location)
        }
        if (modalAction === "add") {
            return addLocation(location);
        }
    }

    const deleteLocation = async () => {
        if (!selectedLocation) {
            console.error('No selected location')
            return;
        }
        try {
            setIsSaving(true);
            await caseApi.deleteLocation(caseId, selectedLocation.id)
            // Update table
            const idx = caseLocations.findIndex(location => location.id === selectedLocation.id);
            caseLocations.splice(idx, 1);
            setCaseLocations([...caseLocations]);
        } catch (e) {
            console.error('Error deleting location');
        } finally {
            setIsSaving(false);
            toggleDeleteLocationModal();
            setSelectedLocation(undefined);
        }
    }

    const AddLocationButton = () => {
        return (
            <React.Fragment>
                <Button
                    variant="primary"
                    className="btn-add"
                    onClick={() => toggleLocationFormModal("add")}>
                    Add Location
                </Button>
            </React.Fragment>
        );
    }
    return (
        <React.Fragment>
            <Table
                classNames={"table-striped"}
                actionButton={AddLocationButton}
                isSearchable={true}
                columns={columns}
                data={caseLocations}
            />
            {isLoading &&
                <div className="d-flex justify-content-center">
                    <Spinner className="text-primary m-2"/>
                </div>
            }
            {!isLoading && caseLocations.length === 0 && <span className="text-muted">No locations found</span>}
            <LocationFormModal
                action={modalAction}
                isSaving={isLoading}
                formError={formError}
                onSave={onSaveLocation}
                show={showAddLocationModal}
                onHide={toggleLocationFormModal}
                location={selectedLocation}/>
            <DeleteLocation
                isSaving={isSaving}
                selectedLocation={selectedLocation}
                onDeleteLocation={deleteLocation}
                show={showDeleteLocationModal}
                onHide={toggleDeleteLocationModal}/>
        </React.Fragment>
    );
}

export default LocationsTable;