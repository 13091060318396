import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthApi from "../api/auth-api";

const authApi = new AuthApi();

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        async function logout() {
            try {
                await authApi.logout();
                localStorage.removeItem("authUser");
            } catch (e) {
            } finally {
                navigate("/login")
            }
        }

        logout();
    }, []);

    return (<></>);
}

export default Logout;