import React, { useEffect, useState } from "react";
import { Spinner, Container, Alert,Row, Col,Table, Card  } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraRetro } from '@fortawesome/free-solid-svg-icons';
import { CameraStatus } from "../../helpers/interfaces";
import CameraApi from "../../api/camera-api";
import {CustomLineGraph, CustomBarGraph} from "components/CustomGraph";
import { CameraStatusElements, CustomProgressBar} from "../../components/CameraStatusElements"

type CameraStatusProps = {
    cameraId: number;
};

interface UnitData {
    data: number;
    units: string;
    }

const CameraStatuses: React.FC<CameraStatusProps> = ({ cameraId }) => {
    const [cameraStatus, setCameraStatus] = useState<CameraStatus | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const fetchCameraStatus = async () => {
        try {
            const api = new CameraApi();
            const status = await api.getCameraStatus(cameraId);
            setCameraStatus(status);
        } catch (err) {
            setError(err instanceof Error ? err : new Error('An error occurred'));
        } finally {
            setLoading(false);
        }
    };

    fetchCameraStatus();
    }, [cameraId]);

    if (loading) {
        return <Spinner animation="border" />;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const getGoogleMapsUrl = (latitude?: number, longitude?: number): string => {
        if (latitude !== undefined && longitude !== undefined) {
            return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
        }
        return '#';
    };

    const timestamps: string[] = [];
    const xdatas: string[] = [];
    if (cameraStatus && Object.keys(cameraStatus).length && Object.keys(cameraStatus).length === 13) {
        for (let i = 0; i < 10; i++) {
            const date = new Date((cameraStatus.timestamp - i * 300) * 1000);
            const newTimestamp = date.toLocaleString();

            timestamps.push(newTimestamp);
            const formattedTime = date.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true
            });
            xdatas.push(formattedTime);
        }
        }
        timestamps.reverse();
        xdatas.reverse()


    const unpackUnitData = (input: string): UnitData => {
        if ((cameraStatus && Object.keys(cameraStatus).length)){
            const dataPattern = /[\d.]+/;
            const unitPattern = /[a-zA-Z]+/;

            const dataMatch = input.match(dataPattern);
            const unitMatch = input.match(unitPattern);

        if (!dataMatch || !unitMatch) {
            return { data: 0.1, units: '' };
        }

        const data = parseFloat(dataMatch[0]);
        const units = unitMatch[0];

        return { data, units };
        }
        return { data: 0.1, units: '' };
        };


    return (
        <div>
            {!(cameraStatus && Object.keys(cameraStatus).length && Object.keys(cameraStatus).length === 13) ? (
            <Alert variant="danger" className="fancy-alert text-center">
                <FontAwesomeIcon icon={faCameraRetro} size="lg" className="icon-bounce" />
                <div><strong>No Camera Status Available</strong>
                </div>
            </Alert>
        ) :  (
                <div>
                    <Row className="mb-3">
                        <Col md={6}>
                            <ul className="list-unstyled">
                                <CameraStatusElements
                                    label="Status"
                                    value={
                                        <span className={`status-value ${cameraStatus?.status.toLowerCase() === 'ok' ? 'status-ok' : 'status-alert'}`}>
                                        {cameraStatus?.status ?? 'N/A'}
                                        </span>
                                    }/>
                                <CameraStatusElements  label="Timestamp" value={new Date(cameraStatus.timestamp * 1000).toLocaleString()} />
                                    {cameraStatus.location && (
                                <CameraStatusElements
                                    label="Location"
                                    value={
                                        <a href={getGoogleMapsUrl(cameraStatus.location.latitude, cameraStatus.location.longitude)} target="_blank" rel="noopener noreferrer">
                                        View on Google Maps
                                        </a>
                                    }/>
                                    )}
                                <CameraStatusElements  label="Video Count" value={cameraStatus.videos?.count ?? 'N/A'} />
                                <CameraStatusElements  label="Last Video" value={new Date(cameraStatus.videos.last_timestamp * 1000).toLocaleString()?? 'N/A'} />
                                <CameraStatusElements  label="Image Count" value={cameraStatus.images?.count ?? 'N/A'} />
                                <CameraStatusElements  label="Last Image" value={new Date(cameraStatus.images.last_timestamp * 1000).toLocaleString()?? 'N/A'} />
                                <li className="my-2"></li>
                                <CustomProgressBar elementName="Capacity"
                                    currentValue={unpackUnitData(cameraStatus.capacity?.remaining).data}
                                    totalValue={unpackUnitData(cameraStatus.capacity?.total).data}
                                    elementUnit={unpackUnitData(cameraStatus.capacity?.remaining).units}
                                />
                                <li className="my-2"></li>
                                <CustomProgressBar elementName="Ram"
                                    currentValue={unpackUnitData(cameraStatus.ram?.remaining).data}
                                    totalValue={unpackUnitData(cameraStatus.ram?.total).data}
                                    elementUnit={unpackUnitData(cameraStatus.ram?.remaining).units}
                                />
                                <CameraStatusElements  label="CPU Load" value={cameraStatus.cpu_load ?? 'N/A'} />
                            </ul>
                        </Col>
                        <Col md={6}>
                            <div key="line-graph-1">
                            <CustomLineGraph
                                xAxisData={xdatas.map(xdata =>xdata )}
                                yAxisData={cameraStatus?.battery}
                                title={`Battery ${timestamps[0]} - ${timestamps[9]}`}
                                yAxisLabel="Battery Level (%)"
                                xAxisLabel="Time"
                            />

                            </div>
                        </Col>
                        </Row>
                        <Row>
                        <Col md={6}>
                            <div key="line-graph-2">
                            <CustomLineGraph
                                xAxisData={xdatas.map(xdata =>xdata )}
                                yAxisData={cameraStatus?.temperature}
                                title={`Temperature ${timestamps[0]} - ${timestamps[9]}`}
                                yAxisLabel="Temperature (°C)"
                                xAxisLabel="Time"
                            />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div key="bar-graph-1">
                            <CustomBarGraph
                                xAxisData={xdatas.map(xdata =>xdata )}
                                yAxisData={cameraStatus?.lte.map(value => Math.round(value)) ?? []}
                                title={`LTE ${timestamps[0]} - ${timestamps[9]}`}
                                yAxisLabel="LTE Strength"
                                xAxisLabel="Time"
                            />
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
};
export default CameraStatuses;