import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

const ManageCasesLayout = (props: any) => {
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Outlet/>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default ManageCasesLayout
