import React from 'react';
import { Card } from 'react-bootstrap';
import classNames from "classnames";


const FolderItem = (props: any) => {
    const {title} = props
    return (
        <>
            <Card
                onClick={props.onClick}
                className={classNames(props.className, "folder-item")}>
                <Card.Body className="folder-item-content">
                    <h4 className="folder-title">{title}</h4>
                </Card.Body>
            </Card>
        </>
    );
}

export default FolderItem;


