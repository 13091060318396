import { BackendApi } from "./backend-api";
import { Camera, CameraConfiguration, GetCameraListResponse,CameraStatus } from "../helpers/interfaces";

export default class CameraApi {
    private backendApi: BackendApi;

    constructor() {
        this.backendApi = BackendApi.Instance;
    }

    async listCameras(args?: any): Promise<GetCameraListResponse> {
        return this.backendApi.getApiResponse({
            endPoint: '/cameras',
            queryStringParams: args
        });
    }

    async updateCamera(camera: Camera) {
        return this.backendApi.getApiResponse({
            endPoint: `/cameras/${camera.id}`,
            method: "PUT",
            data: camera
        })
    }

    async assignCamera(cameraId: number, locationId: any, caseId: any) {
        return this.backendApi.getApiResponse({
            endPoint: `/cameras/${cameraId}/assign`,
            method: "POST",
            data: {
                location_id: locationId,
                case_id: caseId
            }
        })
    }

    async unassignCamera(cameraId: number) {
        return this.backendApi.getApiResponse({
            endPoint: `/cameras/${cameraId}/unassign`,
            method: "POST"
        })
    }

    async deleteCamera(cameraId: number) {
        return this.backendApi.getApiResponse({
            endPoint: `/cameras/${cameraId}`,
            method: "DELETE"
        })
    }

    async addCamera(camera: Camera) {
        return this.backendApi.getApiResponse({
            endPoint: `/cameras`,
            method: "POST",
            data: camera
        })
    }

    async updateCameraConfig(cameraId: number, config: CameraConfiguration, method: any) {
        return this.backendApi.getApiResponse({
            endPoint: `/cameras/${cameraId}/config`,
            method: method,
            data: config
        })
    }

    async listUnassignedCameras(args?: any) {
        return this.backendApi.getApiResponse({
            endPoint: `/cameras/unassigned`,
            queryStringParams: args
        })
    }

    async getCameraStatus(cameraId:number): Promise<CameraStatus> {
        return this.backendApi.getApiResponse({
            endPoint: `/cameras/${cameraId}/status`,
        });
    }
}