import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { User } from "../../../helpers/interfaces";
import CaseApi from "../../../api/case-api";

type IProps = {
    show: boolean;
    isSaving: boolean;
    onHide: () => void;
    onSaveSelectedUsers: (userIds: number[]) => void;
    caseId: number;
}
const caseApi = new CaseApi();

const AssignUsersFormModal = (
    {
        caseId,
        ...props
    }: IProps) => {

    const [users, setUsers] = useState<User[]>([]);
    const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    async function fetchUsers() {
        try {
            setIsLoading(true);
            const resp = await caseApi.listUnassignedUsers(caseId, {
                offset: 0,
                limit: 1000,
            });
            setUsers([...resp.users]);
        } catch (e) {
            console.error('Error loading users');
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        setIsSaving(props.isSaving)
    }, [props.isSaving]);

    useEffect(() => {
        if (!props.show) {
            setUsers([]);
            return;
        }
        setSelectedUserIds([]);
        fetchUsers();
    }, [props.show]);

    const handleInputChange = (event: any, userId: number) => {
        const isSelected = event.target.checked;

        if (isSelected) {
            selectedUserIds.push(userId);
        } else {
            const index = selectedUserIds.indexOf(userId);
            selectedUserIds.splice(index, 1);
        }
        setSelectedUserIds([...selectedUserIds])
    }

    const saveSelectedUsers = async () => {
        props.onSaveSelectedUsers(selectedUserIds)
    }

    return (
        <React.Fragment>
            <Modal
                show={props.show}
                onHide={props.onHide}
                dialogClassName="modal-dialog-centered">
                <Modal.Header
                    onHide={props.onHide} closeButton>
                    <h4 className="modal-title">Add Users
                    </h4>
                </Modal.Header>
                <Modal.Body>
                    {isLoading &&
                        <div className="d-flex justify-content-center">
                            <Spinner className="text-primary m-2"/>
                        </div>
                    }
                    {!isLoading && (users.length > 0 ? (
                        users.map((user, idx) => {
                            return (
                                <Form.Check
                                    key={idx}
                                    id={`user-${user.id}`}
                                    className="mb-2"
                                    type="checkbox"
                                    aria-label={`user-${user.id}`}
                                    onChange={(event) => handleInputChange(event, user.id)}
                                    label={`${user.firstname} ${user.lastname} (${user.email})`}
                                />
                            );
                        })) : <span>No more users to add</span>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {isSaving && <Spinner className="m-2"/>}
                    <Button
                        onClick={saveSelectedUsers}
                        disabled={isSaving || users.length == 0}
                        type="submit"
                        variant="primary">
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>);
}

export default AssignUsersFormModal