import React from "react";

function ErrorPage() {
  return (
    <React.Fragment>
      <div>Error!</div>
    </React.Fragment>
  );
}

export default ErrorPage;
