import { ApiCallMethod } from "../helpers/types";

export class BackendApi {
    baseApiUrl = process.env.REACT_APP_BACKEND_URL;
    private static _instance: BackendApi;

    public static get Instance() {
        if (!BackendApi._instance) {
            BackendApi._instance = new BackendApi()
        }
        return BackendApi._instance;
    }

    private constructor() {
    }

    public async getApiResponse(args: {
        endPoint: string,
        queryStringParams?: Record<string, any>,
        method?: ApiCallMethod,
        data?: Record<string, any>,
    }, includeAuthToken = true): Promise<any> {
        const {endPoint, queryStringParams, method = "GET", data} = args;
        let url = `${this.baseApiUrl}${endPoint}`;
        let headers: any = {};
        // Set Authorization header
        if (includeAuthToken) {
            const authUser = JSON.parse(localStorage.getItem('authUser') || "{}");
            const authorizationHeader = authUser["token"];
            headers['Authorization'] = `Bearer ${authorizationHeader}`
        }

        // If posting JSON, set the request body and Content-Type header
        let body = null;
        if (['POST', 'PUT'].includes(method) && data !== null) {
            body = JSON.stringify(data);
            headers['Content-Type'] = 'application/json';
        }

        // Add query string parameters to the URL
        if (queryStringParams) {
            const usp = new URLSearchParams();
            for (const [key, value] of Object.entries(queryStringParams)) {
                if (value !== '' && value != null) {
                    usp.append(key, value);
                }
            }
            const queryString = usp.toString();
            url += `?${queryString}`;
        }
        return await this.fetchResponse(url, method, headers, body);
    }

    fetchResponse = async (url: string, method: string, headers: {}, body: any) => {
        const response = await fetch(url, {
            method: method || 'GET',
            headers: headers,
            body: body
        });

        // If the response is not OK, throw an ApiError, attempting
        // to parse the response to JSON. If that fails, the response
        // text is included in the error instead.
        if (!response.ok) {
            let respData;
            let respText;

            try {
                respData = await response.json();
            } catch (ex) {
                respText = await response.text();
            }

            if (response.status === 401 && respData.error === "invalid_token") {
                localStorage.removeItem("authUser");
                window.location.replace("/login");
            }

            throw new ApiError({
                data: respData,
                statusCode: response.status,
                text: respText
            });
        }

        const contentType = response.headers.get('content-type');
        // If we fetch an image or file, handle it appropriately
        if (contentType && contentType.indexOf('json') === -1) {
            return response.blob();
        }
        return response.json();
    }
}

export class ApiError {
    data?: any;
    statusCode: number;
    text?: string;

    constructor(args: {
        data?: any
        statusCode: number,
        text?: string,
    }) {
        const {statusCode, text, data} = args;
        this.statusCode = statusCode;
        this.text = text;
        this.data = data;
    }
}


