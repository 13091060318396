import React, { Suspense, useEffect, useState } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import FolderItem from '../../../components/FolderItem';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CaseApi from "../../../api/case-api";
import { Case, CaseLocation } from "../../../helpers/interfaces";
import PageTitle from "../../../components/PageTitle";
import { loading } from "../../../helpers/utils";

const caseApi = new CaseApi();

const CaseLocations = (props: any) => {
    const [isLoading, setIsLoading] = useState(false)
    const [locations, setLocations] = useState<CaseLocation[]>([]);
    const params = useParams();
    const caseId = Number(params.caseId)
    const navigate = useNavigate();
    const routerLocation = useLocation();
    const [selectedCase, setSelectedCase] = useState<any>({})

    // Redirect if invalid case ID
    useEffect(() => {
        if (isNaN(caseId)) {
            setTimeout(() => {
                navigate("/cases");
            }, 0);
        }
    });

    // Fetch data
    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            try {
                const _case = await caseApi.fetchCase(caseId)
                setSelectedCase(_case);

                const {locations} = await caseApi.listLocations({
                    caseId: _case.id
                });
                setLocations(locations)

            } catch (e) {
                console.log("Error fetching data", e);
            } finally {
                setIsLoading(false);
            }
        }

        fetchData();
    }, []);

    const onLocationSelected = (location: CaseLocation) => {
        navigate(`/cases/${selectedCase?.id}/locations/${location.id}`)
    }

    return (
        <Suspense fallback={loading()}>
            <PageTitle
                breadCrumbItems={[
                    {label: "Cases", path: "/cases"},
                    {label: "View Cases", path: "/cases"},
                    {label: `${selectedCase?.name}`, path: `/cases/${selectedCase?.id}`},
                    {label: "Locations", active: true}
                ]}
                title={`${selectedCase?.name}`}
            />
            <Card>
                <Card.Body>
                    {isLoading && loading()
                    }
                    <div className="folder-list-container">
                        {locations?.length === 0 && <span className="text-muted">No locations found</span>}
                        {!isLoading && (locations || []).map((location, idx) => {
                            return (
                                <React.Fragment key={location.id}>
                                    <FolderItem
                                        onClick={() => onLocationSelected(location)}
                                        title={location.name}></FolderItem>
                                </React.Fragment>
                            )
                        })}
                    </div>
                </Card.Body>
            </Card>
        </Suspense>
    )
}

export default CaseLocations
