import { createBrowserRouter } from "react-router-dom";
import RootLayout from './components/layout/RootLayout'

import CasesLayout from "./pages/cases";
import ErrorPage from "./pages/Error";
import HomePage from "./pages/Home";
import LoginPage from "./pages/Login";
import Cameras from "./pages/cameras/Cameras";
import UsersPage from "./pages/users/Users";
import ManageCases from './pages/./cases/manage/ManageCases';
import Cases from './pages/./cases/view/Cases';
import CaseLocations from './pages/./cases/view/CaseLocations';
import LocationDates from "./pages/./cases/view/LocationDates";
import CaseImages from "./pages/cases/view/./case-images/CaseImages";
import Logout from "./pages/Logout"
import { AdminRoute, AuthLoader } from "./helpers/auth"
import CaseDetail from "./pages/./cases/manage/CaseDetail";
import ManageCasesLayout from "./pages/cases/manage";

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout/>,
    errorElement: <ErrorPage/>,
    id: 'root',
    loader: AuthLoader,
    children: [
      {
        path: '',
        element: <HomePage/>,
      },
      {
        path: 'cases',
        element: <CasesLayout/>,
        children: [
          {
            path: '',
            element: <Cases/>
          },
          {
            path: ':caseId',
            element: <CaseLocations/>
          },
          {
            path: ':caseId/locations',
            element: <CaseLocations/>
          },
          {
            path: ':caseId/locations/:locationId',
            element: <LocationDates/>
          },
          {
            path: ':caseId/locations/:locationId/:captureDate',
            element: <CaseImages/>
          },
          {
            path: 'manage',
            id: 'manage',
            loader: AuthLoader,
            element: <ManageCasesLayout/>,
            children: [
              {
                path: '',
                element: <ManageCases/>
              },
              {
                path: ':caseId',
                id: 'manageCase',
                loader: AuthLoader,
                element: <CaseDetail/>
              },
            ]
          }
        ]
      },
      {
        path: '/cameras',
        id: 'cameras',
        loader: AdminRoute,
        element: <Cameras/>
      },
      {
        path: '/users',
        id: 'users',
        loader: AdminRoute,
        element: <UsersPage/>
      }
    ]
  },
  {
    path: '/login',
    element: <LoginPage/>
  },
  {
    path: '/logout',
    element: <Logout/>
  }
])

export default router
